import delegate from "delegate";
import { $$ } from "utils/$";
import * as Event from "utils/Event";

delegate(".more-action__dropdown-trigger", "click", (e) => {
  e.preventDefault();

  const $button = e.delegateTarget;
  const $actionsWrapper = $button.parentNode.querySelector(".more-action__dropdown");
  const active_class = "more-action__dropdown--active";

  $actionsWrapper.classList.add(active_class);
  Event.onOutsideClick([$button, $actionsWrapper], () => {
    $actionsWrapper.classList.remove(active_class);
  });
});

document.addEventListener("turbolinks:before-cache", function() {
  const activeActionWrappers = $$(".more-action__dropdown--active");

  activeActionWrappers.forEach(el => {
    el.classList.remove("more-action__dropdown--active");
  });
});

import { $, $$ } from "utils/$";
import { injectFromResponse } from "utils/replace-dom";
import * as Element from "utils/Element";
import Choices from "choices.js";
import delegate from "delegate";

delegate("[data-open-in-modal]", "click", e => {
  e.preventDefault();
  const $modal = $(`.${e.target.dataset.openInModal}`) || $(".js-modal");
  const $modalContent = $(".js-modal-content");
  const target = e.target;

  if (!$modal) return;

  loadInModal($modalContent, target.href).then(() => {
    $modal.classList.add("modal--open");
    document.dispatchEvent(new Event("modal-content:load"));

    const disabledSaveButton = $$(".js-modal-content [data-disable='true']:not(.js-enable-modal-submit)")
    Array.from(disabledSaveButton.map(button => {
      button.disabled = true
    }));

    const searchableDropdowns = $$(".js-searchable-dropdown");
    if (searchableDropdowns.length <= 0) return;

    searchableDropdowns.forEach(el => {
      new Choices(el, { shouldSort: false, position: "bellow" });
    });
  });
});

delegate(".js-close-modal", "click", e => {
  e.preventDefault();
  const target = e.target;
  const $modal = $(".js-modal");

  if (!Element.inNodeMatching(target, ".js-close-modal")) return;

  closeModal($modal);
});

delegate(".js-modal-backdrop", "click", e => {
  const target = e.target;
  const $backDrop = $(".js-modal-backdrop");
  const $modal = $(`.${e.target.dataset.closeModal}`) || $(".js-modal");

  if (target !== $backDrop) return;

  closeModal($modal);
});

function closeModal($modal) {
  $modal.classList.remove("modal--open");
}

function loadInModal($modalContent, url) {
  const loader = $("#js-loader");

  if (loader) {
    loader.classList.remove("hidden");
  }

  return fetch(url)
    .then(response => response.text())
    .then(injectFromResponse($modalContent, "#js-main-content"))
    .finally(() => {
      if (loader) {
        loader.classList.add("hidden");
      }
    });
}

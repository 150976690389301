import delegate from "delegate";
import * as Element from "utils/Element";
import { $ } from "../utils/$";

delegate(".js-nav-store-menu-button", "click", (e) => {
  if($(".nav-store-search-wrapper").classList.contains("is-open")) {
    return;
  }

  const shouldOpen = $(".nav-store-menu-wrapper").classList.contains("is-closed");
  _toggleMenu(shouldOpen);
});

delegate("body", "click", (e) => {
  if($(".nav-store-menu-wrapper") && _shouldClose() && _outsideClick(e)) {
    _toggleMenu(false);
  }
});

function _toggleMenu(shouldOpen) {
  $(".nav-store-menu-icon").classList.toggle("nav-store-menu-icon-opened", shouldOpen);
  $(".nav-store-menu-icon").classList.toggle("nav-store-menu-icon-closed", !shouldOpen);
  $(".nav-store-menu-wrapper").classList.toggle("is-open", shouldOpen);
  $(".nav-store-menu-wrapper").classList.toggle("is-closed", !shouldOpen);
}

function _shouldOpen() {
  return $(".nav-store-menu-wrapper").classList.contains("is-closed");
}

function _shouldClose() {
  return !_shouldOpen();
}

function _outsideClick(e) {
  let header = $(".nav-sub");
  let wrapper = $(".nav-store-menu-wrapper");

  return ![header, wrapper].some((nav_element) => Element.isOrIncludes(nav_element, e.target));
}

// Collapses store menu before caching the page so that future visits to this
// cached page do not show the preview page with the menu open.
document.addEventListener("turbolinks:before-cache", function() {
  if($(".nav-store-menu-wrapper")) {
    _toggleMenu(false);
  }
});

export default class TeamMemberRemovalConfirmation {
  constructor(el, modalBackdrop, modal) {
    this.el = el;
    this.modalBackdrop = modalBackdrop;
    this.modal = modal;
    this.realLink = el.querySelector(".js-remove-team-member")
    this.confirmButton = this.modal.querySelector(".js-confirm-remove-team-member")
    this.cancelButton = this.modal.querySelector(".js-cancel-team-member-removal")
    this.onClose = this.close.bind(this);
    this.onRemove = this.remove.bind(this);
  }

  open(e) {
    e.preventDefault();
    this.modal.classList.remove("hidden");
    this.confirmButton.addEventListener("click", this.onRemove, false);
    this.modalBackdrop.addEventListener("click", this.onClose, false);
  }

  remove(e) {
    e.preventDefault();
    this.realLink.click()
  }

  close(e) {
    e.preventDefault();
    if (e.target !== this.modalBackdrop && e.target !== this.cancelButton) return;

    this.modal.classList.add("hidden");
    this.modalBackdrop.removeEventListener("click", this.onClose, false);
  }
}

import { $, $$ } from "utils/$";
import delegate from "delegate";

delegate(".js-toggle-disabled", "change", (e) => {
  e.preventDefault();

  toggleItems($$(`.${e.delegateTarget.dataset.target}`), e.delegateTarget.checked);
});

function toggleItems(targets, disabled) {
  targets.forEach((item) => {
    item.disabled = disabled;
    if (disabled) {
      item.classList.add("disabled")
    } else {
      item.classList.remove("disabled")
    }
  });
}

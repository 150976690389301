import { $, $$ } from "utils/$";
import delegate from "delegate";
import { replaceDom } from "../utils/replace-dom";

delegate(".js-single-option", "change", onSingleOptionChange);
delegate(".js-update-gear-selection", "change", postForm);
delegate(".js-toggle-currently-viewing", "click", toggleProductVisibility);
delegate(".js-confirm-gear-selection", "click", () => {
  const form = $("#confirm-gear-selection-form");
  const loader = $("#js-loader");
  loader.classList.remove("hidden");

  if (!form) return;

  form.submit(
   () => loader.classList.add("hidden")
  );
});
delegate(".js-proceed-to-review", "click", () => {
  const loader = $("#js-loader");
  loader.classList.remove("hidden");
});

function onSingleOptionChange(e) {
  const checked = e.delegateTarget.checked;
  const selectedId = e.delegateTarget.dataset.id;
  const categoryId = e.delegateTarget.dataset.categoryId;

  $$(`[data-category-id='${categoryId}']`).forEach((item) => {
    item.checked = checked && item.dataset.id === selectedId;
  });
}

function toggleProductVisibility(e) {
  const productEl = e.delegateTarget.closest(".js-gear-selection-product");
  const field = productEl.querySelector(".js-currently-viewing");
  const isCurrentlyViewing = field.value == "true" ? "false" : "true";

  productEl.classList.toggle(
    "onboarding-selection-item--expanded",
    isCurrentlyViewing == "true"
  );

  productEl.classList.toggle(
    "onboarding-selection-item--closed",
    isCurrentlyViewing == "false"
  );

  field.value = isCurrentlyViewing;
}

function postForm(_e) {
  const gearSelectionForm = $(".js-gear-selection-form");
  const currentFormTarget = `#${gearSelectionForm.id}`;
  const formData = new FormData(gearSelectionForm);
  const loader = $("#js-loader");

  loader.classList.remove("hidden");

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );
  formData.append("_method", "PATCH");
  fetch(gearSelectionForm.dataset.url, {
    method: "post",
    body: formData,
  })
    .then((response) => response.text())
    .then(
      replaceDom(currentFormTarget, {
        childrenOnly: true,
      })
    )
    .finally(() => loader.classList.add("hidden"));
}

import Choices from "choices.js";

export function initializeDropdowns(
  root = document,
  selector = ".js-searchable-dropdown"
) {
  const searchableDropdowns = root.querySelectorAll(selector);
  searchableDropdowns.forEach((dropdownElement) => {
    if (!dropdownElement._choices) {
      const options = dropdownOptions(dropdownElement);

      const choiceElements = dropdownElement.querySelectorAll('option');
      choiceElements.forEach(choice => {
        if (choice.dataset.customProperties) {
          choice.customProperties = JSON.parse(choice.dataset.customProperties);
        }
      });

      dropdownElement._choices = new Choices(dropdownElement, {
        shouldSort: false,
        searchFields: ["label", "customProperties.email"],
        ...options
      });
    }
  });
}

export function tearDownDropdowns(
  root = document,
  selector = ".js-searchable-dropdown"
) {
  const searchableDropdowns = root.querySelectorAll(selector);
  searchableDropdowns.forEach((dropdownElement) => {
    if (dropdownElement._choices) {
      dropdownElement._choices.destroy();
      dropdownElement._choices = null;
    }
  });
}

function dropdownOptions(element) {
  if (element.dataset.searchOnly == "true") {
    return {
      renderChoiceLimit: 1,
    }
  } else {
    return {};
  }
}

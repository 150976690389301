import { $$ } from "utils/$";

let tabs = [];

document.addEventListener("turbolinks:load", () => {
  tabs = $$(".js-tab");
  tabs.forEach(addListener);
});

function addListener(el) {
  el.addEventListener("click", onClick, false);
}

function setTabStates(activeTab) {
  tabs.forEach(function(tab) {
    tab.classList.remove("active");
    tabTarget(tab).classList.add("hidden");

    if (tab === activeTab) {
      tab.classList.add("active");
      tabTarget(tab).classList.remove("hidden");
    }
  });
}

function tabTarget(tab) {
  return document.getElementById(tab.dataset.target);
}

function onClick(e) {
  e.preventDefault();
  setTabStates(e.currentTarget);
}

import { $$ } from "utils/$";

document.addEventListener("turbolinks:load", () => {
  $$(".element-to-copy").forEach(addCopyingOnClickListener);
  $$('.js-copy-sibling-value-button').forEach(el => el.addEventListener("click", copySiblingInputValue, false));
});

function addCopyingOnClickListener(el) {
  el.addEventListener("click", copyTextOnClickToClipboard, false);
}

function copySiblingInputValue(e) {
  if (document.location.protocol != "https:") {
    throw new Error("It is impossible to copy anything by clicking if the connection is not secure");
  }
  const element = e.srcElement;
  const targetElement = element.nextElementSibling;

  if(!targetElement) {
    return
  }

  e.preventDefault();
  navigator.clipboard.writeText(targetElement.value);
  showFlashMessage(targetElement);
  hideActionsWrapper(targetElement);
}

function copyTextOnClickToClipboard(e) {
  if (document.location.protocol != "https:") {
    throw new Error("It is impossible to copy anything by clicking if the connection is not secure");
  }

  e.preventDefault();

  const element = e.srcElement
  navigator.clipboard.writeText(element.value);
  showFlashMessage(element);
  hideActionsWrapper(element);
}

function showFlashMessage(element) {
  const selector = element.dataset.jsFlashClass;
  const flash = document.querySelector(selector).innerHTML;
  document.body.insertAdjacentHTML("beforeend", flash);
  document.dispatchEvent(new Event("flash:load"));
}

function hideActionsWrapper(element) {
  const actionsWrapper = element.parentNode;
  const active_class = "more-action__dropdown--active";

  actionsWrapper.classList.remove(active_class);
}

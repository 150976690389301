import { $$ } from "utils/$";
import * as Element from "utils/Element";

document.addEventListener("turbolinks:load", () => {
  $$(".js-data-table-inline-detail").forEach(addListener);
});

document.addEventListener("table-detail:load", () => {
  $$(".js-data-table-inline-detail").forEach(addListener);
});

function addListener(el) {
  el.addEventListener("click", onClick, false);
  el.style.cursor = "pointer";
}

function onClick(e) {
  if(targetClickedIsNotIgnored(e)) {
    e.preventDefault();
    let parentRow = e.currentTarget;
    let inlineRow = parentRow.nextElementSibling;
    let inlineRowOpenClass = inlineRow.dataset.openClass || "data-table__inline-detail--open";

    parentRow.classList.toggle("data-table__row--expanded");
    inlineRow.classList.toggle(inlineRowOpenClass);
  }
}

function targetClickedIsNotIgnored(e) {
  return !Element.isOrIncludesClass("more-action", e.target);
}

export function inNodeMatching(currentNode, selector) {
  if (currentNode === document) return false;
  if (currentNode.matches(selector)) return true;

  return inNodeMatching(currentNode.parentNode, selector);
}

export function isOrIncludes(element, currentNode) {
  if (currentNode === document) return false;
  if (currentNode === element) return true;

  return isOrIncludes(element, currentNode.parentNode);
}

export function isOrIncludesClass(classString, currentNode) {
  if (currentNode === document) return false;
  if (currentNode.classList.contains(classString)) return true;

  return isOrIncludesClass(classString, currentNode.parentNode);
}

document.addEventListener("side-panel:load", () => {
  initializeEditAssignmentsControllers();
});

function initializeEditAssignmentsControllers() {
  document.querySelectorAll(".js-edit-assignments-controller .js-edit-assignments-toggle")
    .forEach(toggle => toggle.addEventListener("click", handleToggleEvent));
}

function handleToggleEvent(e) {
  const controller = e.currentTarget.closest(".js-edit-assignments-controller");
  const showSelector = e.currentTarget.dataset.editAssignmentsShowSelector;
  const hideSelector = e.currentTarget.dataset.editAssignmentsHideSelector;
  const showElement = controller.querySelector(showSelector);
  const hideElement = controller.querySelector(hideSelector);

  showElement.classList.remove("hidden");
  hideElement.classList.add("hidden");
}

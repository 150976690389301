import delegate from "delegate";

delegate(".js-submit-and-disable", "submit", (e) => {
  if (e.delegateTarget.submitting) {
    e.preventDefault();
    return;
  }
  e.delegateTarget.submitting = true;
  Array.from(
    e.delegateTarget.querySelectorAll("[type=submit]")
  ).map((button) => button.disabled = true)

  e.delegateTarget.submit();
});

import delegate from "delegate";
import { $, $$ } from "../utils/$";

document.addEventListener("turbolinks:load", () => {
  delegate(".js-choose-sales-company", "change", e => {
    e.preventDefault();

    const sales_company_id = $('.js-choose-sales-company').value;
    window.location.assign(`/sales_company_admin_accounts/${sales_company_id}`);
  });
});

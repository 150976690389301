import { $$ } from "utils/$";
import * as Event from "utils/Event";
import delegate from "delegate";

delegate("[data-dropdown-target]", "click", (e) => {
  e.preventDefault();

  const $toggler = e.delegateTarget;
  const targetClass = $toggler.dataset.dropdownTarget;
  const $target = document.querySelector(`.${targetClass}`);
  const openClass = `${targetClass}--is-open`;

  if(!$target) return false;

  if($target.classList.toggle(openClass)) {
    Event.onOutsideClick([$toggler, $target], () => {
      $target.classList.remove(openClass);
    });
  }
});

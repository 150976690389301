import { $ } from "../utils/$";
import { $$ } from "utils/$";
import delegate from "delegate";

delegate(".js-modal-content #equipment_item_serial_number", "input", () => {
  disableEnableSaveButton();
});

delegate(".js-modal-content #equipment_item_imei", "input", () => {
  disableEnableSaveButton();
});

function disableEnableSaveButton() {
  const serialNumberInput = $$(".js-modal-content #equipment_item_serial_number")[0];
  const imeiInput = $$(".js-modal-content #equipment_item_imei")[0];
  const saveButtonForMarkAsReceived = $$(".js-modal-content [type=submit]");
  const serialNumberInputIsNotEmpty =
    serialNumberInput && serialNumberInput.value.trim().length > 0;
  const imeiInputIsNotEmpty = imeiInput && imeiInput.value.trim().length > 0;

  if(serialNumberInputIsNotEmpty || imeiInputIsNotEmpty) {
    Array.from(saveButtonForMarkAsReceived).map((button) => button.disabled = false);
  }
  else {
    Array.from(saveButtonForMarkAsReceived).map((button) => button.disabled = true);
  }
}

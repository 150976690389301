document.addEventListener("turbolinks:load", () => {
  initializeConfirmOverageControllers();
});
document.addEventListener("product-page:load", () => {
  initializeConfirmOverageControllers();
});

function initializeConfirmOverageControllers() {
  document.querySelectorAll(".js-confirm-overage-controller .js-confirm-overage-submit")
    .forEach(submit => submit.addEventListener("click", handleConfirmOverageSubmitClicked));

  document.querySelectorAll(".js-confirm-overage-controller .js-confirm-overage-checkbox")
    .forEach(checkbox => checkbox.addEventListener("click", handleConfirmOverageCheckboxClicked));
}

function handleConfirmOverageSubmitClicked(e) {
  const controller = e.currentTarget.closest(".js-confirm-overage-controller");
  const checkbox = controller.querySelector(".js-confirm-overage-checkbox");
  const label = controller.querySelector(".js-confirm-overage-checkbox-label");
  const required = controller.dataset.confirmOverageRequired === "true"

  if (required && !checkbox.checked) {
    e.preventDefault();
    e.stopPropagation();

    label.classList.add("confirm-overage-error");
  }
}

function handleConfirmOverageCheckboxClicked(e) {
  const controller = e.currentTarget.closest(".js-confirm-overage-controller");
  const label = controller.querySelector(".js-confirm-overage-checkbox-label");

  label.classList.remove("confirm-overage-error");
}

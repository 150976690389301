import { $ } from "../utils/$";

document.addEventListener("turbolinks:load", () => {
  const checkout_enabled = $(`meta[name="hotjar-react-checkout-enabled"]`)?.content;
  const product_page_enabled = $(`meta[name="hotjar-react-product-page-enabled"]`)?.content;
  const sales_company = $(`meta[name="hotjar-sales-company"]`)?.content;
  const store_enabled = $(`meta[name="hotjar-react-store-enabled"]`)?.content;
  const employee_company = $(`meta[name="hotjar-employee-company"]`)?.content;
  const employee_id = $(`meta[name="hotjar-employee-id"]`)?.content;
  const employee_type = $(`meta[name="hotjar-employee-type"]`)?.content;

  if (
    !checkout_enabled &&
    !product_page_enabled &&
    !sales_company &&
    !store_enabled &&
    !employee_company &&
    !employee_id &&
    !employee_type
  ) {
      return;
  }

  window.hj('identify', employee_id, {
    checkout_enabled,
    product_page_enabled,
    sales_company,
    store_enabled,
    employee_company,
    employee_type,
  });
});

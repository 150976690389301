import * as Form from "../utils/Form";
import delegate from "delegate";

delegate(".js-morph-form", "change", morphForm);

function morphForm(event) {
  const $form = event.target.closest("form");

  if (!$form.id) throw new Error("No id found for form.")

  const morphUrl = $form.dataset.morphFrom;

  return Form.postMorphForm($form, morphUrl, `#${$form.id}`);
}

document.addEventListener("turbolinks:load", () => {
  const tables = document.querySelectorAll(".js-software-plan-assignment-table");

  tables.forEach(updateTableWithScroll);
});

function updateTableWithScroll(table) {
  const rows = table.querySelectorAll("tr");

  if(rows.length > 3) {
    table.classList.add("data-table__inline-detail-body--scrollable");
  }
}

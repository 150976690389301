import delegate from "delegate";
import { $, $$ } from "../utils/$";
import { replaceDom } from "utils/replace-dom";

document.addEventListener("turbolinks:load", () => {});

delegate(".js-favorite", "click", (e) => {
  e.preventDefault();
  const externalProductId = e.delegateTarget.dataset.externalProductId;
  const loader = $("#js-loader");

  loader && loader.classList.remove("hidden");

  if(externalProductId) {
    fetch(`/favorite_products/${externalProductId}/toggle`, {
      method: "POST",
      headers: {
        'X-CSRF-Token': $("meta[name='csrf-token']").getAttribute("content")
      }
    })
      .then(function(response) {
       let response_text = response.text();
       return response_text;
     })
      .then(replaceDom(`#favorite_${externalProductId}`))
      .finally(() => {
        loader && loader.classList.add("hidden");
      });
  }
});

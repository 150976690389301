export function serializePart(el) {
  const fields = Array.from(el.querySelectorAll("input,select"));
  const formData = new FormData();

  fields.forEach((field) => {
    if (shouldAppend(field)) {
      formData.append(field.name, field.value);
    }
  });

  return formData;
}

function shouldAppend(field) {
  const isCheckable = field.type == "checkbox" || field.type == "radio";
  if (!isCheckable) return true;

  return field.checked;
}

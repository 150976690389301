import { $$ } from "utils/$";

document.addEventListener("turbolinks:load", () => {
  $$(".js-lazy-load-table-details")
    .forEach(($element) => {
      $element.style.cursor = "pointer";
      $element.addEventListener("click", lazyLoadDetails, false);
    });
});

function lazyLoadDetails(event) {
  event.preventDefault();

  const $row = event.currentTarget;

  $row.classList.toggle("data-table__row--expanded");

  fetchDetailsRow($row)
    .then(($detailsNode) => {
      $detailsNode.classList.toggle("data-table__inline-detail--open");
    })
}

function fetchDetailsRow($node) {
  if (!$node.dataset.detailsLoaded) {
    return fetchAndInsertAfter($node.dataset.detailsUrl, $node);
  } else {
    return Promise.resolve($node.nextSibling);
  }
}

function fetchAndInsertAfter(url, $node) {
  return fetch(url)
    .then((response) => response.text())
    .then((responseBody) => {
      const fragment = document.createElement("tbody");
      fragment.innerHTML = responseBody;

      const details = fragment.children[0];
      $node.parentNode.insertBefore(details, $node.nextSibling);
      $node.dataset.detailsLoaded = true;

      return details;
    })
}

import { $, $$ } from "utils/$";
import * as UtilsEvent from "utils/Event";
import Choices from "choices.js";
import delegate from "delegate";

delegate(".js-side-panel-show", "click", (e) => { fetchAndShowSidePanel(e); } );
delegate(".js-side-panel-close", "click", (e) => { hideSidePanel(e); });

function fetchAndShowSidePanel(e) {
  e.stopPropagation();
  e.preventDefault();

  const sidePanelElement = fetchOrCreateSidePanel(e);
  const url = getSidePanelURL(e);

  showSidePanel(sidePanelElement, url);
}

function hideSidePanel(e) {
  e.preventDefault();

  const sidePanelElement = getSidePanelElement(e);
  sidePanelElement.classList.add("hidden");
}

function fetchOrCreateSidePanel(e) {
  const sidePanelElement = getSidePanelElement(e);

  if(sidePanelElement) {
    return sidePanelElement;
  } else {
    return createSidePanel(e);
  }
}

function createSidePanel(e) {
  const sidePanelSelector = e.delegateTarget.dataset.domId;
  const sidePanelTitle =  e.delegateTarget.dataset.title;
  const template = $(".js-side-panel-template");
  const clone = template.content.cloneNode(true);

  clone.querySelector(".js-side-panel").classList.add(sidePanelSelector);
  clone.querySelector(".js-side-panel-header").innerText = sidePanelTitle;
  clone.querySelector(".js-side-panel-close").dataset.domId = sidePanelSelector;
  document.body.appendChild(clone);

  return getSidePanelElement(e);
}

function showSidePanel(sidePanelElement, url) {
  const sidePanelCardElement = getSidePanelCard(sidePanelElement);
  const sidePanelContentElement = getSidePanelContent(sidePanelElement);

  if (!sidePanelElement.classList.contains("js-side-panel-loaded")) {
    loadSidePanel(url, sidePanelElement);
  }

  sidePanelElement.classList.remove("hidden");
  UtilsEvent.onOutsideClick(sidePanelCardElement, () => {
    sidePanelElement.classList.add("hidden");
  });
}

function loadSidePanel(url, sidePanelElement) {
  const sidePanelContent = getSidePanelContent(sidePanelElement);

  fetch(url)
    .then(response => response.text())
    .then(response => sidePanelContent.innerHTML = response)
    .finally(() => {
      sidePanelElement.classList.add("js-side-panel-loaded");
      document.dispatchEvent(new Event("side-panel:load"));
    });

}

function getSidePanelURL(event) {
  return event.delegateTarget.dataset.contentUrl;
}

function getSidePanelSelector(event) {
  return event.delegateTarget.dataset.domId;
}

function getSidePanelElement(event) {
  return $("." + getSidePanelSelector(event));
}

function getSidePanelLoader(sidePanelElement) {
  return sidePanelElement.querySelector(".js-loader");
}

function getSidePanelCard(sidePanelElement) {
  return sidePanelElement.querySelector(".js-side-panel-card");
}

function getSidePanelContent(sidePanelElement) {
  return sidePanelElement.querySelector(".js-side-panel-content");
}

document.addEventListener("turbolinks:before-cache", function() {
  document.querySelectorAll(".js-side-panel")
    .forEach(sidePanelElement => sidePanelElement.remove());
});

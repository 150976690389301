import delegate from "delegate";

delegate(".js-select-reveal", "change", e => {
  e.preventDefault();

  const target = e.target;
  const elements = document.querySelectorAll(".configure-sso");
  elements.forEach (el => el.classList.add("hidden"));

  const targetElement = document.querySelector(`[data-sso="${target.value}"]`);
  if (targetElement) {
    targetElement.classList.remove("hidden");
  }
});
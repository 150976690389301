import _refiner from "refiner-js";

document.addEventListener("turbolinks:load", () => {
  const project_id = document
    .querySelector(`meta[name="refiner-project-id"]`)
    ?.getAttribute("content");
  const user_id = document
    .querySelector(`meta[name="refiner-user-id"]`)
    ?.getAttribute("content");
  const user_name = document
    .querySelector(`meta[name="refiner-user-name"]`)
    ?.getAttribute("content");
  const user_email = document
    .querySelector(`meta[name="refiner-user-email"]`)
    ?.getAttribute("content");
  const user_locale = document
    .querySelector(`meta[name="refiner-user-locale"]`)
    ?.getAttribute("content");
  const user_employee_id = document
    .querySelector(`meta[name="refiner-user-employee-id"]`)
    ?.getAttribute("content");
  const user_role = document
    .querySelector(`meta[name="refiner-user-role"]`)
    ?.getAttribute("content");
  const user_sign_in_count = document
    .querySelector(`meta[name="refiner-user-sign_in_count"]`)
    ?.getAttribute("content");
  const user_product_requested = document
    .querySelector(`meta[name="refiner-user-product-requested"]`)
    ?.getAttribute("content");
  const user_company = document
    .querySelector(`meta[name="refiner-user-company"]`)
    ?.getAttribute("content");
  const last_order_at = document
      .querySelector(`meta[name="refiner-user-last-order-at"]`)
      ?.getAttribute("content");

  const attributesMissing =
    !project_id &&
    !user_id &&
    !user_name &&
    !user_email &&
    !user_locale &&
    !user_employee_id &&
    !user_role &&
    !user_sign_in_count &&
    !user_product_requested &&
    !user_company &&
    !last_order_at;

  if (attributesMissing) return;
  _refiner("setProject", project_id);

  _refiner("identifyUser", {
    id: user_id,
    name: user_name,
    email: user_email,
    locale: user_locale,
    employee_id: user_employee_id,
    role: user_role,
    sign_in_count: parseInt(user_sign_in_count),
    product_reqesuted: user_product_requested,
    user_company: user_company,
    last_order_at: last_order_at,
  });
});

import delegate from "delegate";

delegate("#regenerate-token-button", "click", (e) => { handleTokenRegeneration(e); } );

function handleTokenRegeneration() {
  const secretTokenInput = document.getElementById("secret-token-input");

  if (secretTokenInput) {
    const responsePromise = fetch("/settings/directory_sync/regenerate_token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    });
    responsePromise.then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Token regeneration failed");
      }
    }).then((data) => {
      secretTokenInput.value = data.new_token;
    }).catch((error) => {
      console.error("Error during token regeneration:", error);
    }).finally(() => {
    });
  }
}

import { $$, $ } from "utils/$";

const IMAGE_EXTENSION_REGEX = /\.(jpe?g|png|gif)$/i;

document.addEventListener("turbolinks:load", function() {
  const $fileUploads = $$(".js-file-upload");

  $fileUploads.forEach(initalizeFileUploadPreview);
});

function initalizeFileUploadPreview($fileUpload) {
  const $input = $fileUpload.querySelector(".input-wrapper__input");
  const $image = $fileUpload.querySelector(".js-file-upload-image");

  $input.addEventListener("change", function(e) {
    const file = e.target.files[0];
    if (!file) return;
    if(!IMAGE_EXTENSION_REGEX.test(file.name)) return;

    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (event) => { $image.src = event.target.result; }
  });
}

import { $, $$ } from "./$";
import * as Url from "./url";
import Choices from "choices.js";
import { replaceDom, injectFromResponse } from "./replace-dom";
import {
  initializeDropdowns,
  tearDownDropdowns,
} from "../utils/searchable-dropdown-utils";

export function morphStateInto(form, options) {
  const formData = new FormData(form);
  const url = Url.putQueryParamsWithFormData(document.location.href, formData);
  const loader = $("#js-loader");

  loader && loader.classList.remove("hidden");

  fetch(url, { method: "GET" })
    .then((response) => response.text())
    .then((response) => {
      tearDownDropdowns(form);
      document.dispatchEvent(new Event("react-unmount:CartButton"));
      replaceDom(options.replace, {
        childrenOnly: true,
      })(response);
      initializeDropdowns(form);
    })
    .finally(() => {
      loader && loader.classList.add("hidden");
      options.callback && options.callback();
    });
}

export function postMorphForm(form, url, formSelector) {
  const formData = new FormData(form);

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  fetch(url, { method: "POST", body: formData })
    .then((response) => response.text())
    .then((response) => {
      tearDownDropdowns(form);
      injectFromResponse(form, formSelector)(response);
      initializeDropdowns(form);
    });
}

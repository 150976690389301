import { $ } from "../utils/$";

document.addEventListener("turbolinks:load", () => {
  const user_id = $(`meta[name="segment-user-id"]`)?.content;
  const sales_company_id = $(`meta[name="segment-sales-company-id"]`)?.content;

  if(!user_id && !sales_company_id) return;

  analytics.page();

  if(!user_id) return;

  analytics.identify(user_id);
});

import delegate from "delegate";

delegate("#add-domain-button", "click", handleAddDomain);

function handleAddDomain(e) {
  e.preventDefault();

  const inputElement = document.getElementById("input-domain-value");
  const cofigureViaWorkOSButton = document.getElementById("configure-via-workos");
  const errorText = document.getElementById("error-text");

  if (!isValidDomainFormat(inputElement.value)) {
    errorText.classList.remove("hidden");
    disabledButton(cofigureViaWorkOSButton)
    return;
  }
  else {
    errorText.classList.add("hidden");
  }

  sendPostRequest("/settings/sso/add_domain", { domain: inputElement.value })
    .then((data) => {
      inputElement.value = data.domain;
      if (data.domain !== "") {
       enablebutton(cofigureViaWorkOSButton)
      }
      else {
        disabledButton(cofigureViaWorkOSButton)
      }
    })
    .catch((error) => {
      console.error("Error during details saving:", error);
    });
}

function sendPostRequest(url, data) {
  const json_data = JSON.stringify(data)

  return fetch(url, {
    method: "POST",
    body: json_data,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": getCSRFToken(),
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("SSO domain save failed");
    }
  });
}

function disabledButton(button) {
  button.classList.remove("enabled-button");
  button.classList.add("disabled-button");
}

function enablebutton(cofigureViaWorkOSButton) {
  cofigureViaWorkOSButton.classList.remove("disabled-button");
  cofigureViaWorkOSButton.classList.add("enabled-button");
}

function getCSRFToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute("content");
}

function isValidDomainFormat(domain) {
  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;
  return domainRegex.test(domain);
}
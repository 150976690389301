document.addEventListener("side-panel:load", () => {
  initializeToggleCheckedControllers();
});

function initializeToggleCheckedControllers() {
  document.querySelectorAll(".js-toggle-checked-controller .js-toggle-checked-source")
    .forEach(source => source.addEventListener("click", handleToggleSourceCheckedEvent));

  document.querySelectorAll(".js-toggle-checked-controller .js-toggle-checked-target")
    .forEach(target => target.addEventListener("click", handleToggleTargetCheckedEvent));
}

function handleToggleSourceCheckedEvent(e) {
  const controller = e.currentTarget.closest(".js-toggle-checked-controller");
  const checked = e.currentTarget.checked;

  const targets = controller.querySelectorAll(".js-toggle-checked-target");
  targets.forEach(target => updateTargetCheckbox(target, checked));
}

function updateTargetCheckbox(target, checked) {
  target.checked = checked;
  target.dispatchEvent(new Event("change"));
}

function handleToggleTargetCheckedEvent(e) {
  const controller = e.currentTarget.closest(".js-toggle-checked-controller");
  const source = controller.querySelector(".js-toggle-checked-source");

  const targets = controller.querySelectorAll(".js-toggle-checked-target");
  const allChecked = Array.from(targets).every(target => target.checked);

  source.checked = allChecked;
}

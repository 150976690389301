import { $, $$ } from "utils/$";
import delegate from "delegate";

const HIDDEN_CLASS = "hidden";

delegate(".js-toggle-visibility", "click", (e) => {
  e.preventDefault();
  toggleItems($$(e.delegateTarget.dataset.target));
});

delegate(".js-toggle-visibility-on-change", "change", (e) => {
  if(e.delegateTarget.closest("form") && e.delegateTarget.closest("form").dataset.jsRequest === "true") {
    postForm(e);
  } else {
    toggleItems($$(e.delegateTarget.dataset.target));
  }
});

delegate("[data-toggle-next]", "click", (e) => {
  e.preventDefault();
  const $button = e.delegateTarget;

  const $toggleButtons = $$("[data-toggle-next]")
    .filter((button) => button.dataset.toggleNext == $button.dataset.toggleNext);
  const $toggleTargets = $$($button.dataset.toggleNext);

  const $toggleTarget = $toggleTargets[$toggleButtons.indexOf($button)];

  $toggleTarget.classList.toggle(HIDDEN_CLASS);

  if($button.dataset.toggleNextText) {
    const oldText = $button.textContent;
    $button.textContent = $button.dataset.toggleNextText;
    $button.dataset.toggleNextText = oldText;
    $button.classList.toggle("open")
  }
});

delegate(".js-show-hidden-content", "click", (e) => {
  e.preventDefault();

  $$(e.delegateTarget.dataset.target).forEach((item) => {
    item.classList.remove(HIDDEN_CLASS);
  });
  e.delegateTarget.classList.add(HIDDEN_CLASS);
});

function toggleItems(targets) {
  targets.forEach((item) => {
    item.classList.toggle(HIDDEN_CLASS);
  });
}

function postForm(e) {
  const companyForm = e.delegateTarget.closest("form");
  const formData = new FormData(companyForm);
  const formDataUrl = companyForm.dataset.url;

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  fetch(formDataUrl, { method: "POST", body: formData })
  .then(_response => location.reload())
}

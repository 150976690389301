import { $$, $ } from "utils/$";

document.addEventListener("turbolinks:load", function() {
  const $fileAttachments = $$(".js-file-attachment");
  $fileAttachments.forEach(initializeFileAttachment);
});

function initializeFileAttachment($fileAttachment) {
  let $inputField = $("#import_csv_file")
  initializeRemoveAttachment($inputField);
  initializeFileChange($inputField);
  initializeAttachmentChange($fileAttachment);
}

function initializeRemoveAttachment($inputField) {
  const $removeAttachmentButton = $(".js-unattach-button")
  $removeAttachmentButton.addEventListener("click", (e) => {
    $inputField.value = "";

    toggleTextHidden(0);
    toggleInputHidden();
  });
}

function initializeFileChange($inputField) {
  $inputField.addEventListener("change", (e) => {
    if (e.target.files.length > 0) {
      toggleInputHidden();
    }
  });
}

function initializeAttachmentChange($fileAttachment) {
  $fileAttachment.addEventListener('change', (e) => {
    let $fileNameText = $("#js-file-name-text")
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    toggleTextHidden(file.size);
    $fileNameText.textContent = `${file.name}`;
  });
}

function toggleInputHidden() {
  const $altText = $(".js-import-alt-text")
  const $fileName = $(".js-file-name")
  const $fileInputLabel = $(".js-file-input-label")

  $altText.classList.toggle("hidden");
  $fileName.classList.toggle("hidden");
  $fileInputLabel.classList.toggle("hidden");
}

function toggleTextHidden(fileSize) {
  const fileSizeLimit = $("#import_csv_file").dataset.fileSizeLimit;

  if (fileSize > fileSizeLimit) {
    $(".js-file-size-text").classList.add("hidden");
    $(".js-file-size-warning").classList.remove("hidden");
  } else {
    $(".js-file-size-text").classList.remove("hidden");
    $(".js-file-size-warning").classList.add("hidden");
  }
}

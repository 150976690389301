document.addEventListener("side-panel:load", () => {
  initializeFilterTableControllers();
});

function initializeFilterTableControllers() {
  document.querySelectorAll(".js-filter-table-controller .js-filter-table-source")
    .forEach(source => source.addEventListener("keyup", handleFilterTableEvent));
}

function handleFilterTableEvent(e) {
  const controller = e.currentTarget.closest(".js-filter-table-controller");
  const source = controller.querySelector(".js-filter-table-source");
  const valueBlank = (!source.value || /^\s*$/.test(source.value));
  const valueRegex = new RegExp(source.value, "gi");

  const tableContexts = controller.querySelectorAll(".js-filter-table-context");
  tableContexts.forEach(tableContext => filterTable(tableContext, valueRegex, valueBlank));
}

function filterTable(tableContext, valueRegex, valueBlank) {
  const targets = tableContext.querySelectorAll(".js-filter-table-target");
  const noResults = tableContext.querySelector(".js-filter-table-no-results");
  const table = tableContext.querySelector("table");

  targets.forEach(target => filterTableTarget(target, valueRegex, valueBlank));

  const allHidden = Array.from(targets).every(target => target.classList.contains("hidden"));
  noResults.classList.toggle("hidden", !allHidden);
  table.classList.toggle("hidden", allHidden);
}

function filterTableTarget(target, valueRegex, valueBlank) {
  const targetValue = target.dataset.filterTableValue;
  const matched = valueBlank || targetValue.match(valueRegex);
  target.classList.toggle("hidden", !matched);
}

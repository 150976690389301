import delegate from "delegate";

delegate(".js-delete-company-button", "click", (e) => {
  let actionsWrapper = e.delegateTarget.parentNode;

  actionsWrapper.classList.remove('more-action__dropdown--active');
});

delegate(".js-modal-content #company_name_verification", "input", (e) => {
  let modalElement = e.delegateTarget.closest(".js-modal-content");
  let companyName = modalElement.querySelector("#company_name_to_confirm").value;
  let confirmationInputText = modalElement.querySelector("#company_name_verification").value;
  let saveButton = modalElement.querySelector(".js-modal-content [type=submit]");

  saveButton.disabled = confirmationInputText != companyName;
});

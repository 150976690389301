import { replaceDom } from "../utils/replace-dom";
import { $ } from "../utils/$";
import * as Url from "../utils/url";
import debounce from "debounce";
import { tearDownDropdowns } from "../utils/searchable-dropdown-utils";


export class StoreSearchComponent {
  constructor() {
    this.resultsElementSelector = ".js-form-content";
    this.storeNavElementSelector = ".nav-store nav";
    this.storeSearchWrapperElementSelector = ".nav-store-search-wrapper";
    this.storeLinkElementSelector = ".js-store-link";
    this.abortController = null;
  }

  search() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.abortController = new AbortController();

    fetch(this.formURL, { method: "GET", signal: this.abortController.signal })
      .then((response) => response.text())
      .then((html) => this._loadResults(html))
      .finally(() => this.abortController = null);
  }

  searchIfSearchInputPresentAndResultsEmpty() {
    if (this._isSearchPresent() && this._isResultsEmpty()) {
      this.spinnerElement.classList.add("is-loading");
      this.search();
    }
  }

  updateSearchUIAndBeginSpinner() {
    this.updateSearchUI();
    if (this._isSearchPresent()) {
      this.spinnerElement.classList.add("is-loading");
    }
  }

  updateSearchUI() {
    if (this._isSearchPresent()) {
      this._hideMenuUI();
      this.showSearchUI();
      this.inputElement.classList.add("has-value");
    } else {
      this.clearSearchUI();
    }
  }

  showSearchUI() {
    this._toggleSearchUI(true, document);
  }

  hideSearchUI(rootElement = document) {
    this._toggleSearchUI(false, rootElement);
  }

  clearSearchUI() {
    this.hideSearchUI();
    this.inputElement.value = "";
    this.inputElement.classList.remove("has-value");
    this.resultsElement.innerHTML = "";
    this._updateLocationAndCacheSearchPage();
    this._updateStoreLinksWithSearchTerm();
  }

  setSearchInputValue(value) {
    this.inputElement.value = value;
    this.inputElement.classList.add("has-value");
    this.resultsElement.innerHTML = "";
  }

  get formElement() {
    return $("#js-search-store-form");
  }

  get inputElement() {
    return $(".js-nav-store-search");
  }

  get spinnerElement() {
    return $(".js-store-search-spinner");
  }

  get resultsElement() {
    return $(".js-form-content");
  }

  get storeMenuWrapperElement() {
    return $(".nav-store-menu-wrapper");
  }

  get storeMenuIconElement() {
    return $(".nav-store-menu-icon");
  }

  get formURL() {
    const formData = new FormData(this.formElement);
    return Url.putQueryParamsWithFormData(this.formElement.action, formData);
  }

  _loadResults(html) {
    this.spinnerElement.classList.remove("is-loading");
    if (this._isSearchPresent()) {
      replaceDom(this.resultsElementSelector, { childrenOnly: true })(html);
    };

    this._updateLocationAndCacheSearchPage();
    this._updateStoreLinksWithSearchTerm();
  }

  _isSearchPresent() {
    return !this._isSearchBlank();
  }

  _isSearchBlank() {
    const inputValue = this.inputElement.value;
    return /^\s*$/.test(inputValue);
  }

  _isResultsEmpty() {
    return this.resultsElement.innerText == "";
  }

  _toggleSearchUI(shouldOpen, rootElement) {
    rootElement.querySelector(this.storeNavElementSelector).classList.toggle("nav-store-search-opened", shouldOpen);
    rootElement.querySelector(this.storeSearchWrapperElementSelector).classList.toggle("is-open", shouldOpen);
    rootElement.querySelector(this.storeSearchWrapperElementSelector).classList.toggle("is-closed", !shouldOpen);
  }

  _hideMenuUI() {
    this.storeMenuIconElement.classList.remove("nav-store-menu-icon-opened");
    this.storeMenuIconElement.classList.add("nav-store-menu-icon-closed");
    this.storeMenuWrapperElement.classList.remove("is-open");
    this.storeMenuWrapperElement.classList.add("is-closed");
  }

  _updateLocationAndCacheSearchPage() {
    const urlWithSearchTerm = this._buildURLWithSearchTerm(window.location.href).href;
    history.replaceState(history.state, "", urlWithSearchTerm);

    const snapshot = Turbolinks.controller.view.getSnapshot();
    tearDownDropdowns(snapshot.bodyElement);
    Turbolinks.defer(() => Turbolinks.controller.cache.put(urlWithSearchTerm, snapshot.clone()));
  }

  _updateStoreLinksWithSearchTerm() {
    document.querySelectorAll(this.storeLinkElementSelector).forEach(function(linkElement) {
      const url = this._buildURLWithSearchTerm(linkElement.href);
      linkElement.href = url.href;
    }.bind(this));
  }

  _buildURLWithSearchTerm(url) {
    const searchString = this.formURL.search;
    const searchParams = new URLSearchParams(searchString);
    const searchTerm = searchParams.get("q");
    const newURL = new URL(url);

    if(searchTerm) {
      newURL.searchParams.set("q", searchTerm);
    } else {
      newURL.searchParams.delete("q");
    }
    return newURL;
  }
}

document.addEventListener("side-panel:load", () => {
  const planForms = document.querySelectorAll(".js-edit-plan-form");

  planForms.forEach(initializePlanFormEventListener);
});

document.addEventListener("turbolinks:load", () => {
  const planForms = document.querySelectorAll(".js-edit-plan-form");

  planForms.forEach(initializePlanFormEventListener);
});

function setDynamicFormBasedOnKind(form, value) {
  const costTypeLabel = form.querySelector(".form-cost__type");
  const allOptions = form.querySelector("#software-plan-all-options");

  form.classList.remove(
    "plan-selected-subscription",
    "plan-selected-one_off",
    "plan-selected-variable",
    "plan-selected-free"
  );
  costTypeLabel.classList.remove("required-field");
  allOptions.classList.remove("hidden");

  if(value == "subscription") {
    form.classList.add("plan-selected-subscription");
    costTypeLabel.classList.add("required-field");
  } else if (value == "one_off") {
    form.classList.add("plan-selected-one_off");
  } else if (value == "variable") {
    form.classList.add("plan-selected-variable");
  } else if (value == "free") {
    form.classList.add("plan-selected-free");
  } else {
    allOptions.classList.add("hidden");
  }
}

function setDynamicFormBasedOnCostType(form, value) {
  form.classList.remove(
    "cost-selected-per_user",
    "cost-selected-flat_charge",
    "cost-selected-variable_charge",
  );

  if(value == "per_user") {
    form.classList.add("cost-selected-per_user");
  } else if (value == "flat_charge") {
    form.classList.add("cost-selected-flat_charge");
  } else if (value == "variable_charge") {
    form.classList.add("cost-selected-variable_charge");
  }
}

function initializePlanFormEventListener(form) {
  const kind = form.querySelector(".js-edit-plan-kind-input");
  const costType = form.querySelector(".js-edit-plan-cost-type-input");
  const costDecimal = form.querySelector(".js-fixed-decimals");
  costDecimal.value = parseFloat(costDecimal.value).toFixed(2);

  kind.addEventListener('change', function() {
    setDynamicFormBasedOnKind(form, this.value);
  });
  costType.addEventListener('change', function() {
    setDynamicFormBasedOnCostType(form, this.value);
  });
  costDecimal.addEventListener('change', function() {
    this.value = parseFloat(this.value).toFixed(2);
  });
}

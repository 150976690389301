import { $ } from "../utils/$";
import delegate from "delegate";
import { replaceDom } from "utils/replace-dom";

const HIDDEN_CLASS = "hidden";
const TAB_KEY_CODE = 9;

delegate(".js-remove-saved-indicator-on-keydown", "keydown", e => {
  if (e.keyCode !== TAB_KEY_CODE) {
    e.delegateTarget
      .closest("fieldset")
      .querySelector(".js-saved-indicator")
      .classList.remove("active");
    
    const $footer = $("#js-replace-footer");
    $footer.querySelector(".js-was-saved-button").classList.add(HIDDEN_CLASS);
    $footer.querySelector(".js-not-saved-button").classList.remove(HIDDEN_CLASS);
  }
});

delegate(".js-submit-form-on-change", "change", e => {
  const $form = e.delegateTarget.closest("form");
  const formData = new FormData($form);
  const $fieldset = e.delegateTarget.closest("fieldset");
  const $savedIndicator = $fieldset.querySelector(".js-saved-indicator");
  const fiveSeconds = 5000;

  let request = fetch($form.action, {
    method: $form.method,
    body: formData
  }).then(response => response.text());

  if ($("#js-replace-progress")) {
    request.then(replaceDom("#js-replace-progress"));
  }

  request.then(replaceDom(`#${$fieldset.id}`)).then(() => {
    $savedIndicator.classList.add("active");
    setTimeout(() => $savedIndicator.classList.add("hide"), fiveSeconds);
  });

  if ($("#js-replace-footer")) {
    request.then(replaceDom("#js-replace-footer"));
  }
});

import { $, $$ } from "../utils/$";
import { replaceDomMulti } from "../utils/replace-dom";
import Choices from "choices.js";
import delegate from "delegate";
import { debounce } from "debounce";
import directImageUpload from "./direct-image-upload";
import { initializeDropdowns, tearDownDropdowns } from "../utils/searchable-dropdown-utils";

delegate(".js-reload-form", "change", postFormData, false);
delegate(".js-lease-start-date", "focusout", postFormData, false);
delegate(".js-equipment-name", "keyup", debounce(changeName, 150));
delegate(".js-equipment-replacegroup-suggestion", "change", pickFromSuggestion);
delegate(".js-image-preview-field", "change", uploadFile);

const assignableSelect = $("#js-equipment-item-form #equipment_item_assignable_gid");
if (!!assignableSelect) {
  assignableSelect.addEventListener("change", clearPayor)
}

function clearPayor() {
  const payorSelect = $("#equipment_item_payor_gid");
  payorSelect._choices.setChoiceByValue("");
}

function uploadFile(e) {
  directImageUpload(e.target, postFormData);
}

function pickFromSuggestion() {
  const $checked = $(".js-equipment-replacegroup-suggestion:checked");
  $(".js-equipment-name").value = $checked.dataset.name;
  $(".js-product-image-url").value = $checked.dataset.picture || "";
  selectCategory($(".js-equipment-category"), $checked.dataset.category);
}

function selectCategory(select, value) {
  select._choices.setChoiceByValue(value);
  postFormData();
}

function changeName() {
  $(".js-equipment-replacegroup-search").value = $(".js-equipment-name").value;

  postFormData();
}

function dropdownOptions(element) {
  if (element.dataset.searchOnly == "true") {
    return {
      renderChoiceLimit: 1,
    }
  } else {
    return {};
  }
}

function postFormData() {
  const form = $("#js-equipment-item-form");
  const formData = new FormData(form);

  formData.delete("_method");
  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  fetch("/equipment/forms", { method: "POST", body: formData })
    .then((response) => response.text())
    .then(replaceDomMulti(".js-form-data", { childrenOnly: true }))
    .then(() => {

      $$(".js-searchable-dropdown").forEach((dropdownElement) => {
        const options = dropdownOptions(dropdownElement);

        const choiceElements = dropdownElement.querySelectorAll('option');
        choiceElements.forEach(choice => {
          if (choice.dataset.customProperties) {
            choice.customProperties = JSON.parse(choice.dataset.customProperties);
          }
        });

        dropdownElement._choices = new Choices(dropdownElement, {
          shouldSort: false,
          searchFields: ["label", "customProperties.email"],
          ...options
        });
      });
    });
}

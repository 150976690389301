import * as Element from "./Element";

export function onOutsideClick(wrappers, fn) {
  wrappers = [].concat(wrappers);

  window.addEventListener("click", onOutsideClickInner);

  function onOutsideClickInner(e) {
    const clickedInWrapper = wrappers
      .some((wrapper) => Element.isOrIncludes(wrapper, e.target));

    if(clickedInWrapper) return;

    window.removeEventListener("click", onOutsideClickInner);

    fn(e);
  }
}

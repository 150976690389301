import delegate from "delegate";

delegate(".js-disable-button-input", "change", e => {
  const input = e.target;
  const form = input.closest("form");
  const button = form.querySelector(".js-disable-button-target");

  if (input.value.length > 0) {
    button.disabled = false;
  } else {
    button.disabled = true;
  }
});

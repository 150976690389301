import { $, $$ } from "../utils/$";
import { replaceDom } from "../utils/replace-dom";
import CategoryPicker from "../utils/CategoryPicker";
import delegate from "delegate";

document.addEventListener("turbolinks:load", () => {
  initCategoryPickers();
});

delegate(".js-reload-budget-form", "change", () => {
  $(".budget-loader-spinner").classList.add("is-loading");
  postFormData();
});

delegate(".js-remove-budget-category", "click", e => {
  e.preventDefault();
  const $target = e.target;
  const $nodeToDelete = $target.closest(".js-budget-category");

  $nodeToDelete.querySelector("input.js-destroy-field").value = "true";
  $nodeToDelete.classList.add("hidden");
  postFormData();
});

delegate(".js-open-category-picker", "click", e => {
  e.preventDefault();
  const picker = e.target
    .closest(".js-budget-category")
    .querySelector(".js-category-picker").categoryPicker;
  picker.open(e);
});

function postFormData() {
  const $form = $("#js-budget-form");
  const formData = new FormData($form);

  formData.delete("_method");
  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  fetch($form.dataset.remotePost, { method: "POST", body: formData })
    .then(response => response.text())
    .then(replaceDom(".js-form-data", { childrenOnly: true }))
    .then(initCategoryPickers)
    .then(() => $(".budget-loader-spinner").classList.remove("is-loading"));
}

function initCategoryPickers() {
  $$(".js-category-picker").forEach(el => {
    if (el.categoryPicker != undefined) {
      return;
    }
    el.categoryPicker = new CategoryPicker(el, postFormData);
  });
}

document.addEventListener("side-panel:load", () => {
  initializeBulkPlanControllers();
});

document.addEventListener("turbolinks:load", () => {
  initializeBulkPlanControllers();
});

function initializeBulkPlanControllers() {
  document.querySelectorAll(".js-bulk-plan-controller .js-bulk-plan-parent")
    .forEach(parent => parent.addEventListener("change", handleParentChangeEvent));

  document.querySelectorAll(".js-bulk-plan-controller .js-bulk-plan-child")
    .forEach(child => child.addEventListener("change", handleChildChangeEvent));

  document.querySelectorAll(".js-bulk-plan-controller .js-bulk-plan-visibility")
    .forEach(checkbox => checkbox.addEventListener("click", handleVisibilityCheckedEvent));
}

function handleParentChangeEvent(e) {
  const controller = e.currentTarget.closest(".js-bulk-plan-controller");
  const newSoftwarePlanID = e.currentTarget.value;

  controller.querySelectorAll(".js-searchable-dropdown.js-bulk-plan-child")
    .forEach((childDropdown) => childDropdown._choices.setChoiceByValue(newSoftwarePlanID));
}

function handleChildChangeEvent(e) {
  const controller = e.currentTarget.closest(".js-bulk-plan-controller");
  updateParent(controller)
}

function handleVisibilityCheckedEvent(e) {
  const controller = e.currentTarget.closest(".js-bulk-plan-controller");

  updateParentVisibility(controller);
  setTimeout(function(){ updateParent(controller) }, 1);
}

function updateParent(controller) {
  const parentDropdown = controller.querySelector(".js-bulk-plan-parent");
  const visibleOptions = controller.querySelectorAll(".data-table__row:not(.disabled-container) .js-bulk-plan-child option");
  if (visibleOptions.length > 0) {
    const candidateOption = visibleOptions[0]
    const allEqual = Array.from(visibleOptions).every(option => option.value == candidateOption.value);

    if(allEqual) {
      parentDropdown._choices.setChoiceByValue(candidateOption.value);
    } else {
      parentDropdown._choices.setChoiceByValue("mixed");
    }
  }
}

function updateParentVisibility(controller) {
  const parentRow = controller.querySelector(".js-bulk-plan-parent-row");
  const parentDropdown = controller.querySelector(".js-bulk-plan-parent");
  const targets = controller.querySelectorAll(".js-bulk-plan-child-row .js-bulk-plan-visibility");
  const anyChecked = Array.from(targets).some(target => target.checked);
  const shouldDisable = !anyChecked;

  parentRow.classList.toggle("disabled-container", shouldDisable);
  if(shouldDisable) {
    parentDropdown._choices.disable();
  } else {
    parentDropdown._choices.enable();
  }
}

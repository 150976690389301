import morphdom from "morphdom";

export function replaceDom(targetId, settings) {
  return function(responseBody) {
    const fragment = buildFragment(responseBody);
    const target = document.querySelector(targetId);
    const newContent = fragment.querySelector(targetId);

    morphdom(target, newContent, settings);
    return Promise.resolve(newContent);
  };
}

export function replaceDomMulti(identifier, settings) {
  return function(responseBody) {
    const fragment = buildFragment(responseBody);
    const sourceRegions = fragment.querySelectorAll(identifier);
    const targetRegions = document.querySelectorAll(identifier);

    sourceRegions.forEach(function(target, i) {
      morphdom(targetRegions[i], sourceRegions[i], settings);
    });

    return Promise.resolve(fragment);
  };
}

export function injectFromResponse(targetNode, responseBodyId) {
  return function(responseBody) {
    const fragment = buildFragment(responseBody);
    const newContent = fragment.querySelector(responseBodyId);

    if (!newContent) {
      throw new Error(
        `Found no element matching ${responseBodyId} in response body.`
      );
    }

    morphdom(targetNode, newContent, { childrenOnly: true });
    return Promise.resolve(newContent);
  };
}

function buildFragment(responseBody) {
  const fragment = document.createElement("div");
  fragment.innerHTML = responseBody;

  return fragment;
}

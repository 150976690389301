// I know we already have a file-upload.js but that seems very tied to the avatar implementation :(
import { DirectUpload } from "@rails/activestorage";

const IMAGE_EXTENSION_REGEX = /\.(jpe?g|png|gif)$/i;

export default function directImageUpload(el, cb) {
  const file = el.files[0];
  if (!file) return;
  if (!IMAGE_EXTENSION_REGEX.test(file.name)) return;

  const url = el.dataset.directUploadUrl;
  const upload = new DirectUpload(file, url);

  upload.create((error, blob) => {
    if (!error) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", blob.signed_id);
      hiddenField.name = el.name;
      document.querySelector("form").appendChild(hiddenField);
      cb();
    }
  });
  el.value = null;
}

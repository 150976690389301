import { $ } from "../utils/$";
import delegate from "delegate";

delegate(".js-user-role-controller .js-selected-user-role", "change", (e) => {
  const controller = e.target.closest(".js-user-role-controller");
  const attestationManagerContainer = controller.querySelector(".js-attestation-manager-container")
  const attestationManagerText = controller.querySelector(".js-attestation-manager-text")
  const skipMailerContainer = controller.querySelector(".js-skip-mailer-container")
  const targetValue = e.delegateTarget.value
  const admin = e.delegateTarget.dataset.companyAdmin

  toggleContainerVisibility(attestationManagerContainer, targetValue, admin)
  toggleContainerVisibility(attestationManagerText, targetValue, admin)
  toggleContainerVisibility(skipMailerContainer, targetValue, admin)
  handleConfirmationModal(controller, targetValue)
});

function toggleContainerVisibility(container, value, admin) {
  if (container) {
    container.classList.toggle("hidden", value == admin);
  }
}

function handleConfirmationModal(controller, selectedRoleName) {
  const confirmationMessage = controller.dataset.confirmationMessage
  const actualRoleName = controller.dataset.actualRoleName
  const submitButton = controller.querySelector(".js-submit-employment-form")

  if (actualRoleName != undefined) {
    if (actualRoleName != selectedRoleName) {
      submitButton.dataset.confirm = confirmationMessage;
    } else {
      delete submitButton.dataset.confirm;
    }
  }
}

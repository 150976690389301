import { $ } from "utils/$";
import * as Element from "utils/Element";

document.addEventListener("turbolinks:load", () => {
  const bulkInviteWrapper = $("#bulk-invite");

  if (!bulkInviteWrapper) return;

  const form = bulkInviteWrapper.closest("form");
  const placeholderNode = $(".js-bulk-invite-row-placeholder");

  const lastFormRow = Array.from(
    form.querySelectorAll(".js-bulk-invite-row")
  ).pop();
  const formRowParent = lastFormRow.parentNode;
  const formRowBlueprint = lastFormRow.cloneNode(true);

  form.addEventListener("keypress", (e) => {
    if (!e.target.matches("input")) return;
    if (e.key !== "Enter") return;
    e.preventDefault();

    addBulkInviteRow();
  });

  form.addEventListener("click", (e) => {
    if (!Element.inNodeMatching(e.target, ".js-bulk-invite-row-delete")) return;

    const parentFormGroup = e.target.closest(".js-bulk-invite-row");
    parentFormGroup.remove();
  });

  placeholderNode.addEventListener("focus", addBulkInviteRow, true);

  function addBulkInviteRow() {
    const formRowClone = formRowBlueprint.cloneNode(true);
    formRowParent.insertBefore(formRowClone, placeholderNode);
    requestAnimationFrame(() => formRowClone.querySelector("input").focus());
    animateElement(placeholderNode);
  }
});

function animateElement(element) {
  element.classList.add("form-group--animate");
  setTimeout(() => {
    element.classList.remove("form-group--animate");
  }, 500);
}

const IGNORED_FORM_KEYS = ["authenticity_token", "_method"];

export function putQueryParamsWithFormData(url, formData) {
  const newUrl = new URL(url.toString());

  Array.from(formData.entries())
    .filter(([key, _value]) => !IGNORED_FORM_KEYS.includes(key))
    .forEach(([key, value]) => newUrl.searchParams.append(key, value));

  return newUrl;
}

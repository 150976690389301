import delegate from "delegate";
import { $ } from "utils/$";
import * as Event from "utils/Event";

delegate(".js-feature-description", "click", (e) => {
  e.preventDefault();

  const $button = e.delegateTarget;
  const $tooltip =
    $button.parentElement.querySelector("ul").closest(".tooltip--feature-description")
  const active_class = "tooltip--feature-description--active";

  $tooltip.classList.add(active_class);
  Event.onOutsideClick([$button, $tooltip], () => {
    $tooltip.classList.remove(active_class);
  });
});

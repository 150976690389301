import { $, $$ } from "utils/$";
import delegate from "delegate";
import { injectFromResponse } from "../utils/replace-dom";

delegate(".js-load-more", "click", (e) => {
  e.preventDefault();
  const url = e.delegateTarget.href;
  e.delegateTarget.removeAttribute("href");
  e.delegateTarget.classList.add("disabled");

  const replaceNodeId = e.delegateTarget.dataset.morphReplace;
  const $replaceNode = document.getElementById(replaceNodeId);

  fetch(url)
    .then((response) => {
      return response.text();
    })
    .then(injectFromResponse($replaceNode, `#${replaceNodeId}`));
});

import { $ } from "utils/$";
import { injectFromResponse } from "utils/replace-dom";
import delegate from "delegate";
import { replaceDom } from "../utils/replace-dom";
import debounce from "debounce";

// Navigate clicks within store modal
delegate(".js-store-modal-link", "click", (e) => {
  e.preventDefault();

  const $modalContent = $(".js-modal-content");

  return fetch(e.delegateTarget.href)
    .then((response) => response.text())
    .then(injectFromResponse($modalContent, ".js-modal-content"));
});

delegate(".js-store-modal-back-link", "click", (e) => {
  e.preventDefault();
  search();
});

const debouncedKeyup = debounce(function(e) {
  search();
}, 400);

delegate(".js-modal-search-field", "keydown", (e) => {
  // User has pressed Enter key but we don't want to submit the form
  if (e.keyCode === 13) {
    e.preventDefault();
    search();
  }
});

delegate(".js-modal-search-field", "keyup", debouncedKeyup);

function search() {
  const form = $(".js-modal-search");
  const formData = new FormData(form);
  const searchParams = new URLSearchParams(formData).toString()
  const url = new URL(form.action);
  url.search = searchParams;

  fetch(url, { method: "GET" })
    .then((response) => response.text())
    .then(replaceDom(".js-modal-content", { childrenOnly: true }));
}

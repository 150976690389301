import { $ } from "../utils/$";
import * as Url from "../utils/url";
import delegate from "delegate";
import { injectFromResponse } from "utils/replace-dom";
import Choices from "choices.js";

delegate(".js-open-product-request-modal", "click", (e) => {
  e.preventDefault();

  submitFormAndUpdate(e.target.closest("form")).then(() => {
    $(".js-modal").classList.add("modal--open");
    initSelect();
  });
});

delegate(".js-submit-shipping-details", "click", (e) => {
  e.preventDefault();
  submitFormAndUpdate(e.target.closest("form")).then(() => initSelect());
});

delegate(".js-toggle-edit-shipping-details", "click", (e) => {
  e.preventDefault();
  const modalContent = $(".modal__content");

  modalContent
    .querySelector(".product-request__confirm")
    .classList.toggle("active");
  modalContent
    .querySelector(".product-request__confirm")
    .classList.remove("initial");
  modalContent
    .querySelector(".product-request__edit-shipping-details")
    .classList.toggle("active");
});

delegate(".js-modal-submit-product-request", "click", (e) => {
  e.preventDefault();
  const $form = e.target.closest("form");
  const $shippingDetailsForm = $(".modal__content").querySelector(
    ".js-shipping-details-form"
  );

  const formData = new FormData($form);

  Array.from(new FormData($shippingDetailsForm).entries())
    .forEach(([key, value]) => formData.append(key, value));

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );
  $form.querySelector("[type=submit]").disabled = true

  return fetch($form.action, { method: "POST", body: formData })
    .then((response) => {
      return response.text();
    })
    .then(injectFromResponse($(".js-modal"), "#js-response-content"))
    .then(() => initSelect());
});

function submitFormAndUpdate(form) {
  const formData = new FormData(form);
  const url = Url.putQueryParamsWithFormData(new URL(form.action), formData);

  return fetch(url)
    .then((response) => {
      return response.text();
    })
    .then(injectFromResponse($(".js-modal"), "#js-response-content"));
}

function initSelect() {
  new Choices($(".js-modal").querySelector(".js-searchable-dropdown"), {
    shouldSort: false,
  });
}

import { $, $$ } from "utils/$";
import Choices from "choices.js";

document.addEventListener("modal-content:load", () => {
  initializePayorOptionsControllers();
});

function initializePayorOptionsControllers() {
  document.querySelectorAll(".js-payor-options-controller .js-payor-options-reload-input")
    .forEach(input => input.addEventListener("change", handlePayorOptionsChangedEvent));
}

function handlePayorOptionsChangedEvent(e) {
  const controller = e.currentTarget.closest(".js-payor-options-controller");
  const target = controller.querySelector(".js-payor-options-target");
  const formData = new FormData(controller);

  formData.delete("_method");
  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  fetch("/equipment/payor_options", { method: "POST", body: formData })
    .then((response) => response.text())
    .then((response) => target.innerHTML = response)
    .then(() => {
      $$(".js-searchable-dropdown").forEach((el) => {
        if (!el._choices) {
          el._choices = new Choices(el, { shouldSort: false });
        }
      });
    });
}

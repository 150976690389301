import { $ } from "../utils/$";
import { serializePart } from "../utils/part-serializer";
import { replaceDom, injectFromResponse } from "../utils/replace-dom";
import delegate from "delegate";

let storeTarget;

delegate(".js-budget-selector", "change", () => postForm());

// Show or hide starter kit form
delegate(".js-clone-starter-kit", "change", (e) => {
  const budget_id = $("#employment_budget_id").value;
  const url = `/starter_kit_duplications/${e.delegateTarget.value}?budget_id=${budget_id}`;
  const loader = $("#js-loader");

  loader.classList.remove("hidden");

  fetch(url)
    .then((response) => response.text())
    .then(
      replaceDom("#js-starter-kit-form-contents", {
        childrenOnly: true,
      })
    )
    .finally(() => loader.classList.add("hidden"));
});

// Open modal
delegate(".js-add-starter-kit-product", "click", (e) => {
  e.preventDefault();

  const loader = $("#js-loader");
  const modal = $("#js-starter-kit-modal");

  loader.classList.remove("hidden");

  storeTarget = e.target;

  fetch(e.target.dataset.url)
    .then((response) => response.text())
    .then(injectFromResponse(modal, "#js-store-modal"))
    .finally(() => loader.classList.add("hidden"))
});

// Select product from modal
delegate(".js-store-modal-product", "click", (e) => {
  e.preventDefault();
  const $alwaysIncludedInStarterKitInput = storeTarget.parentNode.querySelector(
    ".js-always-included-in-starter-kit"
  );

  storeTarget.value = e.delegateTarget.dataset.remoteId;
  $alwaysIncludedInStarterKitInput.value =
    e.delegateTarget.dataset.alwaysIncludedInStarterKit == "true";

  postForm();
  $("#js-starter-kit-modal").innerHTML = "";
});

delegate(".js-update-multiple-options", "change", (e) => {
  const $alwaysIncludedInStarterKitInput = e.delegateTarget;
  const $category = $alwaysIncludedInStarterKitInput.closest(".js-starter-kit-category-contents");
  const $multipleOptionsCheckBoxForCategory = $category.querySelector(".js-multiple-options");

  const $alwaysIncludedItemsForCatgory = Array.from(
    $category.querySelectorAll("[id$='always_included_in_starter_kit']")
  ).find(item => item.checked);

  if ($alwaysIncludedInStarterKitInput.checked) {
    $multipleOptionsCheckBoxForCategory.checked = true;
  }

  if (!$alwaysIncludedItemsForCatgory) {
    $multipleOptionsCheckBoxForCategory.checked = false;
  }
})

// Updating allowance requires a reload in order to mark overage on items
delegate(".js-toggle-starter-kit-category-allowance", "change", () =>
  postForm()
);
delegate(".js-starter-kit-category-allowance", "change", () => postForm());

// If there's overage, you can click this to auto-set allowance
delegate(".js-starter-kit-update-allowance", "click", (e) => {
  e.preventDefault();

  const $allowanceInput = e.target
    .closest(".js-starter-kit-category")
    .querySelector(".js-starter-kit-category-allowance");

  $allowanceInput.value = e.target.dataset.allowance;

  postForm();
});

delegate(".js-starter-kit-add-category", "click", (e) => {
  e.preventDefault();
  postForm("add_category");
});

delegate(".js-starter-kit-remove-category", "click", (e) => {
  const $target = e.target;
  const $category = e.target.closest(".js-starter-kit-category");
  const $label = $category.querySelector(".starter-kit-category-remove span");
  const $categoryContents = $category.querySelector(
    ".js-starter-kit-category-contents"
  );

  $categoryContents.classList.toggle("visually-hidden");
  updateLabelText($target, $label);
});

function updateLabelText(target, label) {
  const useRemoveCategoryText = !target.checked;
  label.textContent = useRemoveCategoryText
    ? label.dataset.removeCategoryText
    : label.dataset.cancelRemoveCategoryText;
}

function postForm(action) {
  const form = $("#js-starter-kit-form");
  const url = new URL(form.dataset.url, document.location.href);
  const formData = serializePart(form);
  const loader = $("#js-loader");

  // Applicable in the employment form
  const $budgetId = $("#employment_budget_id");

  if ($budgetId && $budgetId.value) {
    url.searchParams.append("budget_id", $budgetId.value);
  }

  loader.classList.remove("hidden");

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );
  formData.append("button", action || "reload");
  formData.append("_method", form.dataset.method);

  fetch(url.toString(), { method: "POST", body: formData })
    .then((response) => response.text())
    .then(
      replaceDom("#js-starter-kit-form-contents", {
        childrenOnly: true,
      })
    )
    .finally(() => loader.classList.add("hidden"))
}

import * as Form from "../utils/Form";
import delegate from "delegate";
import { $, $$ } from "../utils/$";
import { replaceDom } from "utils/replace-dom";

const STORE_PRODUCT_FORM_TARGET = "#js-store-product-form-contents";

delegate(".js-show-payment-options", "change", (e) => {
  const form = e.target.closest("form");

  uncheckPaymentOptionInputElement(form)

  Form.morphStateInto(form, { replace: STORE_PRODUCT_FORM_TARGET, callback: () => dispatchProductLoadedEvent() });
});

delegate(".js-reload-selection", "change", (e) => {
  const form = e.target.closest("form");

  const formData = new FormData(form);

  Form.morphStateInto(form, { replace: STORE_PRODUCT_FORM_TARGET, callback: () => dispatchProductLoadedEvent() });
});

delegate(".js-reload-selection + label", "click", uncheckButton);

function dispatchProductLoadedEvent () {
  document.dispatchEvent(new Event("product-page:load"));
}

function uncheckButton(e) {
  const form = e.target.closest("form");
  const label = e.delegateTarget;
  const button = label.previousElementSibling

  if (!button.checked) return;

  e.preventDefault();
  button.checked = false;

  Form.morphStateInto(form, { replace: STORE_PRODUCT_FORM_TARGET })
}

function uncheckPaymentOptionInputElement (form) {
  const checkedPaymentOptionInputElement =
    form.querySelector("input[name='store_product_form[external_variant_id]'][checked='checked']")
  if(checkedPaymentOptionInputElement) {
    checkedPaymentOptionInputElement.checked = false;
  }
}

import { $$ } from "utils/$";
import { replaceDom } from "utils/replace-dom";

document.addEventListener("turbolinks:load", initiateLazyRefresh);

function initiateLazyRefresh() {
  $$("[data-lazy-refresh-url]").forEach((refreshableElement) => {
    if (!refreshableElement.id) {
      throw new Error("Elements with [data-lazy-refresh-url] must also have an id.");
    }

    const url = refreshableElement.dataset.lazyRefreshUrl;

    if (!url) return;

    fetch(url)
      .then((response) => response.text())
      .then(replaceDom(`#${refreshableElement.id}`))
      .then(() => initializeTableDetails())
      .then(() => setTimeout(initiateLazyRefresh, 1000))
  });
}

function initializeTableDetails() {
  document.dispatchEvent(new Event("table-detail:load"));
}

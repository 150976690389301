import delegate from "delegate";

delegate(".js-filter-toggle-all", "click", (e) => {
  e.preventDefault();

  const $parent = e.delegateTarget.closest(".filter-form__category");
  const checkboxes = Array.from(
    $parent.querySelectorAll("input[type=checkbox]")
  );
  const toggleState = getCheckboxToggleState(checkboxes);

  checkboxes.forEach((item) => (item.checked = toggleState));
  updateButtonText($parent);
});

delegate(".js-filter-checkbox", "change", (e) => {
  const $parent = e.delegateTarget.closest(".filter-form__category");
  updateButtonText($parent);
});

function getCheckboxToggleState(checkboxes) {
  return !checkboxes.every((checkbox) => checkbox.checked == true);
}

function updateButtonText($parent) {
  const button = $parent.querySelector(".js-filter-toggle-all");
  const checkboxes = Array.from(
    $parent.querySelectorAll("input[type=checkbox]")
  );

  if (!button) return;

  const useCheckAllText = getCheckboxToggleState(checkboxes);
  button.textContent = useCheckAllText
    ? button.dataset.checkAllText
    : button.dataset.uncheckAllText;
}

delegate(".js-reset-filter", "click", () => {
  const $form = document.querySelector("#js-filter-form");
  const $root = document.querySelector(".filter-form__main");
  const $childParents = $root.querySelectorAll(".filter-form__category")
  $form.reset();
  $childParents.forEach((parent) => updateButtonText(parent));
})

import delegate from "delegate";
import { $ } from "utils/$";
import * as Event from "utils/Event";

delegate(".js-popout-trigger", "click", (e) => {
  e.preventDefault();

  const $button = e.target;
  const $moreStatsWrapper = e.target.previousElementSibling;
  const active_class = "popout--active";

  $moreStatsWrapper.classList.add(active_class);
  Event.onOutsideClick([$button, $moreStatsWrapper], () => {
    $moreStatsWrapper.classList.remove(active_class);
  });
});

export default class TeamMemberAdd {
  constructor(el, modalBackdrop, modal, employeeNameFilter, employments) {
    this.el = el;
    this.modalBackdrop = modalBackdrop;
    this.modal = modal;
    this.employeeNameFilter = employeeNameFilter;
    this.employments = employments;
    this.onClose = this.close.bind(this);
    this.onQueryChange = this.filterPotentialEmployeesListByName.bind(this);
  }

  open(e) {
    e.preventDefault();
    this.employeeNameFilter.addEventListener("keyup", this.onQueryChange, false);
    this.modal.classList.remove("hidden");
    this.modalBackdrop.addEventListener("click", this.onClose, false);
  }

  filterPotentialEmployeesListByName(e) {
    e.preventDefault();

    const query = e.target.value.toLowerCase();

    this.employments.forEach((employment) => {
      const row = employment.closest(".js-potential-team-member");
      const employeeNameMatchesQuery = row.textContent.toLowerCase().includes(query);

      if (employeeNameMatchesQuery) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    })
  }

  close(e) {
    if (e.target !== this.modalBackdrop) return;

    this.modal.classList.add("hidden");
    this.modalBackdrop.removeEventListener("click", this.onClose, false);
  }
}

import { $ } from "../utils/$";
import { replaceDom } from "utils/replace-dom";

document.addEventListener("turbolinks:load", () => loadStockLevels());
document.addEventListener("product-page:load", () => loadStockLevels());

function loadStockLevels() {
  const delivery = $('.stock-levels');

  if(delivery) {
    const variant_id = $('#variant_external_product_id').value;
    fetch(`/products/${variant_id}/stock_levels`, {
      method: "GET",
    })
      .then(function(response) {
        let response_text = response.text();
        return response_text;
      })
      .then(replaceDom('.stock-levels'))
  }
}

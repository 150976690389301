import delegate from "delegate";
export default class CategoryPicker {
  constructor(el, callback) {
    this.el = el;
    this.outsideEl = this.el.querySelector(".js-category-picker-backdrop");
    this.categoryNameField = this.el.querySelector(".js-category-name-field");
    this.onClose = this.close.bind(this);
    this.el
      .querySelector(".js-submit-budget-category")
      .addEventListener("click", this.postForm.bind(this), false);
    this.callback = callback;

    delegate(
      this.el,
      ".js-category-parent",
      "change",
      this.onParentChange.bind(this)
    );
    delegate(
      this.el,
      ".js-category-child",
      "change",
      this.onChildChange.bind(this)
    );
  }

  postForm(e) {
    e.preventDefault();
    this.outsideEl.removeEventListener("click", this.onClose, false);
    this.callback();
  }

  updateName() {
    const selected = Array.from(this.el.querySelectorAll("input:checked"));
    const names = selected.map($checkbox => this.getName($checkbox));
    const uniqueNames = new Set(names);

    this.categoryNameField.value = Array.from(uniqueNames).join(", ");
  }

  open(e) {
    e.preventDefault();
    this.el.classList.remove("hidden");
    this.outsideEl.addEventListener("click", this.onClose, false);
  }

  close(e) {
    if (e.target !== this.outsideEl) return;
    this.el.classList.add("hidden");
    this.outsideEl.removeEventListener("click", this.onClose, false);
  }

  onParentChange(e) {
    const $parent = e.target;
    this.childrenFor($parent).forEach(i => (i.checked = $parent.checked));
    this.updateName();
  }

  onChildChange(e) {
    const $child = e.target;
    const $parent = this.el.querySelector(`#${$child.dataset.parent}`);

    if (this.allChildrenChecked($parent)) {
      $parent.checked = true;
    } else {
      $parent.checked = false;
    }

    this.updateName();
  }

  allChildrenChecked(parent) {
    return this.childrenFor(parent).every(i => i.checked);
  }

  childrenFor($parent) {
    return Array.from(
      this.el.querySelectorAll(`[data-parent='${$parent.id}']`)
    );
  }

  labelFor($checkbox) {
    return $checkbox.nextElementSibling.textContent;
  }

  getName($checkbox) {
    if ($checkbox.classList.contains("js-category-parent")) {
      return this.labelFor($checkbox);
    } else {
      let $parent = this.el.querySelector(`#${$checkbox.dataset.parent}`);

      return this.allChildrenChecked($parent)
        ? this.labelFor($parent)
        : this.labelFor($checkbox);
    }
  }
}

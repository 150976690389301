import { $$ } from "../utils/$";

document.addEventListener("turbolinks:load", () => {
  $$(".js-chart-interval").forEach(el =>
    el.addEventListener("click", setChartInterval, false)
  );
});

function setChartInterval(e) {
  e.preventDefault();

  $$(".js-chart-interval").forEach(el => el.classList.remove("active"));
  e.currentTarget.classList.add("active");

  const lineChart = Chartkick.charts["chart-1"];
  lineChart.updateData(e.currentTarget.dataset.intervalUrl);
}

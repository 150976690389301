import { $ } from "utils/$";
import { injectFromResponse, replaceDom } from "utils/replace-dom";
import * as Element from "utils/Element";
import delegate from "delegate";

delegate(".js-open-cart", "click", e => {
  e.preventDefault()
  const $cart = $(".js-cart");
  const $cartContent = $(".js-cart-content");

  if (!$cart) return;

  $cart.classList.add("open");

  loadInCart($cartContent, e.delegateTarget.href)
    .then(() => $cart.classList.add("cart--open"));
})

delegate(".js-close-cart", "click", e => {
  e.preventDefault();
  const target = e.target
  const $cart = $(".js-cart")

  if (!Element.inNodeMatching(target, ".js-close-cart")) return;

  closeCart($cart);
})

delegate(".js-cart-backdrop", "click", e => {
  const target = e.target;
  const $backDrop = $(".js-cart-backdrop")
  const $cart = $(".js-cart")

  if (target !== $backDrop) return;

  closeCart($cart)
})

delegate(".js-logout", "click", e => {
  localStorage.removeItem('velory-cart');
});

delegate(".js-cart-remove-action", "click", e => {
  e.preventDefault();
  const formData = new FormData();
  const $checkoutContent = $(".js-checkout-content");
  const $cartContent = $(".js-cart-content");
  const $cartBadge = $(".js-cart-badge");
  const loader = $("#js-loader");
  loader && loader.classList.remove("hidden");

  formData.append(
    "authenticity_token",
    $("meta[name='csrf-token']").getAttribute("content")
  );

  let request = fetch(e.delegateTarget.href, {
    method: "DELETE",
    body: formData
  }).then(response => response.text())
    .finally(() => loader && loader.classList.add("hidden"))

  request.then(
    injectFromResponse($cartContent, ".js-cart-content")
  );

  request.then(
    injectFromResponse($cartBadge, ".js-cart-badge")
  );

  if($checkoutContent) {
    request.then(
      injectFromResponse($checkoutContent, ".js-checkout-content")
    )
  }
})

function closeCart($cart) {
  $cart.classList.remove("open");
}

function loadInCart($cartContent, url) {
  return fetch(url)
    .then((response) => response.text())
    .then(injectFromResponse($cartContent, ".js-cart-content"))
}

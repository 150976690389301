import delegate from "delegate";
import * as Element from "utils/Element";
import { $ } from "../utils/$";
import { StoreSearchComponent } from "../utils/StoreSearchComponent";
import debounce from "debounce";
const storeSearchComponent = new StoreSearchComponent;

delegate(".js-nav-store-search", "keypress", (event) => { _ignoreEnterKeyPress(event) });
delegate(".js-nav-store-search", "keyup", debounce(storeSearchComponent.search.bind(storeSearchComponent), 500));
delegate(".js-nav-store-search", "click", () => { storeSearchComponent.searchIfSearchInputPresentAndResultsEmpty() });
delegate(".js-nav-store-search", "keyup", () => { storeSearchComponent.updateSearchUIAndBeginSpinner() });
delegate(".js-nav-store-search", "click", () => { storeSearchComponent.updateSearchUI() });
delegate(".js-nav-store-clear-search", "click", () => { storeSearchComponent.clearSearchUI() });
delegate(".js-nav-store-close-search", "click", () => { storeSearchComponent.hideSearchUI() });
delegate("body", "click", (event) => { _hideSearchUIOnOutsideClick(event) });

function _ignoreEnterKeyPress(event) {
  if (event.keyCode === 13 || event.which === 13) {
    event.preventDefault();
    event.stopPropagation();
  }
}

function _hideSearchUIOnOutsideClick(event) {
  if(_isSearchComponentPresent() && !_insideSearchClick(event)) {
    storeSearchComponent.hideSearchUI();
  }
}

function _isSearchComponentPresent(rootElement = document) {
  return rootElement.querySelector(".nav-store-search-wrapper");
}

function _insideSearchClick(event) {
  return Element.isOrIncludes($(".nav-sub"), event.target);
}

function _isNavigatingWithinStore(event) {
  return _isSearchComponentPresent() && _isSearchComponentPresent(event.data.newBody);
}

function _isNavigatingToStorePreview(event) {
  return _isSearchComponentPresent(event.data.newBody) && _isTurbolinksPreview();
}

function _isTurbolinksPreview() {
  return document.documentElement.hasAttribute("data-turbolinks-preview");
}

function _resetSearchToUrlState(url, event) {
  storeSearchComponent.setSearchInputValue(url.searchParams.get("q"));
  storeSearchComponent.hideSearchUI();
  storeSearchComponent.hideSearchUI(event.data.newBody);
}

document.addEventListener("turbolinks:before-render", function(event) {
  if(_isNavigatingWithinStore(event)) {
    const browserURL = new URL(window.location.href);
    const formURL = storeSearchComponent.formURL;
    const searchStringURL = browserURL.searchParams.get("q");
    const searchStringForm = formURL.searchParams.get("q");

    if(searchStringURL && searchStringURL !== searchStringForm) {
      _resetSearchToUrlState(browserURL, event);
    }
  }

  if (_isNavigatingToStorePreview(event)) {
    storeSearchComponent.hideSearchUI(event.data.newBody);
  }
});

import { $, $$ } from "utils/$";
import delegate from "delegate";

const HIDDEN_CLASS = "hidden";

delegate(".js-modal-shipping-selector", "click", (el) => toggleRadioButtonBehaviour(el))
delegate(".js-display-shipping-options", "click", (el) => displayShippingOptions(el))

function toggleRadioButtonBehaviour(el) {
  const target = el.target;
  const $hiddenTarget = $(".shipping-info-edit");
  const $customSelector = $(".js-custom-shipping-info-selector");
  const $displayShippingOptionsButton = $(".js-display-shipping-options")
  if (target.type != "radio") return;

  if (target == $customSelector) {
    $hiddenTarget.classList.toggle(HIDDEN_CLASS);
    $displayShippingOptionsButton && $displayShippingOptionsButton.classList.remove(HIDDEN_CLASS)
  } else {
    $hiddenTarget.classList.add(HIDDEN_CLASS);
    $displayShippingOptionsButton && $displayShippingOptionsButton.classList.add(HIDDEN_CLASS)
  }

  setShippingTo(target);
}

function setShippingTo(target) {
  const shippingLabel = $(".js-shipping-info-venue");

  if(!shippingLabel) return;

  shippingLabel.textContent = target.dataset.displayName;
}

function displayShippingOptions(el) {
  el.preventDefault();
  const $hiddenTarget = $(".shipping-info-edit");
  const $displayShippingOptionsButtons = $$(".js-display-shipping-options");

  if (!$displayShippingOptionsButtons) return;

  $hiddenTarget.classList.toggle(HIDDEN_CLASS);
  $displayShippingOptionsButtons.map((el) => {
    el.classList.add(HIDDEN_CLASS);
  })
}

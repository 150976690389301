import delegate from "delegate";
import { $ } from "utils/$";
import * as Event from "utils/Event";

delegate(".js-product-breakdown", "click", (e) => {
  e.preventDefault();

  const $button = e.delegateTarget;
  const $tooltip = $(".tooltip--price-breakdown");
  const active_class = "tooltip--price-breakdown--active";

  $tooltip.classList.add(active_class);
  Event.onOutsideClick([$button, $tooltip], () => {
    $tooltip.classList.remove(active_class);
  });
});

import { $, $$ } from "../utils/$";
import TeamMemberRemovalConfirmation from "../utils/TeamMemberRemovalConfirmation";
import TeamMemberAdd from "../utils/TeamMemberAdd";
import delegate from "delegate";

document.addEventListener("turbolinks:load", () => {
  initTeamMemberConfirmations();
  initTeamMemberAdd();
});

delegate(".js-open-team-member-removal-confirmation", "click", e => {
  e.preventDefault();
  const picker = e.target
    .closest(".js-team-member")
    .teamMemberRemovalConfirm;
  picker.open(e);
});

delegate(".js-open-team-member-add", "click", e => {
  e.preventDefault();
  const picker = e.target.teamMemberAdd;
  picker.open(e);
});

function initTeamMemberConfirmations() {
  $$(".js-team-member").forEach(el => {
    if (el.teamMemberRemovalConfirm != undefined) {
      return;
    }

    const modalBackdrop = document.querySelector(".js-team-member-removal-confirm-backdrop");
    const modal = document.querySelector(".js-team-member-removal-confirm");
    el.teamMemberRemovalConfirm = new TeamMemberRemovalConfirmation(
      el,
      modalBackdrop,
      modal,
    );
  });
}

function initTeamMemberAdd() {
  $$(".js-open-team-member-add").forEach(el => {
    if (el.teamMemberAdd != undefined) {
      return;
    }

    const modalBackdrop = document.querySelector(".js-team-member-add-backdrop");
    const modal = document.querySelector(".js-team-member-add-modal");
    const employeeNameFilter = document.querySelector(".js-team-members-add-filter");
    const employments = document.querySelectorAll(".js-potential-team-member");

    el.teamMemberAdd = new TeamMemberAdd(
      el,
      modalBackdrop,
      modal,
      employeeNameFilter,
      employments,
    );
  });
}

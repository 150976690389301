import { $ } from "utils/$";
import { injectFromResponse, replaceDom } from "utils/replace-dom";
import * as Element from "utils/Element";
import delegate from "delegate";

document.addEventListener("turbolinks:load", () => {
  const integrationCredentials = document.querySelectorAll(".js-integration-credentials")
  if(integrationCredentials.length > 0) {
    toggleSaveButton();
  }

  const userGroupsInput = document.querySelector(".js-uapi-user-groups")
  if(userGroupsInput) {
    toggleUpdateButton("js-uapi-user-groups", ".js-uapi-user-groups-button");
  }

  scrollToAuthorizeButton();
});

delegate(".js-select-reveal", "change", e => {
  e.preventDefault();

  const target = e.target;
  const value = target.value;
  const otherElements = document.querySelectorAll(".configure-directory-sync");
  otherElements.forEach (el => el.classList.add("hidden"));

  const targetElement = document.querySelector(`[data-directory-kind="${value}"]`);
  if (targetElement) {
    targetElement.classList.remove("hidden");
  }
});

delegate(".js-go-to-step", "click", e => {
  e.preventDefault();

  const target = e.target;
  const step = target.dataset.stepTarget;
  goToStep(step);
  toggleSaveButton();
  toggleUpdateButton("js-uapi-user-groups", ".js-uapi-user-groups-button");
});

delegate(".js-step", "click", e => {
  e.preventDefault();

  const activeStep = $(".step.active-step");
  const activeStepHasCredentialField = activeStep
    .getElementsByClassName("js-integration-credentials").length > 0;

  if(activeStepHasCredentialField) {
    handleIntegrationCredentials();
  } else {
    nextStep();
    toggleSaveButton();
  }
});

delegate(".js-zoom", "click", e => {
  e.preventDefault();
  e.target.classList.toggle("zoomed-in");
});

delegate(".js-integration-credentials", "input", () => {
  toggleSaveButton();
});

delegate(".js-uapi-user-groups", "input", () => {
  toggleUpdateButton("js-uapi-user-groups", ".js-uapi-user-groups-button");
});

delegate(".js-uapi-user-groups-button", "click", () => {
  const input = document.querySelector(".js-uapi-user-groups");
  const url = new URL(input.dataset.url);
  const data = { user_group_names: input.value };

  sendRequest(url, "PATCH", data);
});

function buildCurrentStep() {
  const active = $(".active-step");
  if (!active) return 0;

  return parseInt(active.dataset.step);
}

function buildTotalSteps() {
  const steps = document.querySelectorAll(".step");
  return steps.length;
}

function toggleSuccessPage() {
  const currentStepIndex = buildCurrentStep();
  const nextStepIndex = currentStepIndex + 1;
  document.querySelector(".all-steps").classList.add("hidden");
  document.querySelector(".success-page").classList.remove("hidden");
  markAsComplete(currentStepIndex);
  toggleActive(currentStepIndex, nextStepIndex);
}

function toggleNextStep() {
  const currentStepIndex = buildCurrentStep();
  const nextStepIndex = currentStepIndex + 1;

  markAsComplete(currentStepIndex);
  toggleActive(currentStepIndex, nextStepIndex);
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function markAsComplete() {
  const currentStepIndex = buildCurrentStep();
  document.querySelector(`[data-step="${currentStepIndex}"]`).classList.add("completed-step");
}

function toggleActive(currentStepIndex, nextStepIndex) {
  toggleActiveForList(document.querySelectorAll(`[data-step="${currentStepIndex}"]`))
  toggleActiveForList(document.querySelectorAll(`[data-step="${nextStepIndex}"]`));
}

function toggleActiveForList(elements) {
  elements.forEach((el) => {
    el.classList.toggle("active-step");
  });
}

function goToStep(stepIndex) {
  const currentStepIndex = buildCurrentStep();

  toggleActive(currentStepIndex, stepIndex)
}

function nextStep() {
  const currentStep = buildCurrentStep();
  const totalSteps = buildTotalSteps();

  if(currentStep === totalSteps) {
    toggleSuccessPage();
  } else {
    toggleNextStep();
  }
}

function toggleSaveButton() {
  const activeStep = $(".step.active-step");

  if (!activeStep) return;

  const credentialsFields = activeStep.getElementsByClassName("js-integration-credentials");
  const credentialsFieldsDisplayed = credentialsFields.length > 0;
  const saveButton = document.querySelector(".js-step");

  if (credentialsFieldsDisplayed) {
    toggleCredentialsSaveButton(credentialsFields, saveButton);
  } else {
    saveButton.disabled = false;
  }
}

function toggleCredentialsSaveButton(credentialsField, saveButton) {
  const credentialsArray = Array.from(credentialsField);
  const requiredFields = credentialsArray.filter(el => el.hasAttribute("required"));
  const anyRequiredCredentialsBlank = requiredFields
    .some(element => element.value.trim() === '');

  toggleButton(anyRequiredCredentialsBlank, saveButton);
}

function toggleUpdateButton(inputClass, buttonClass) {
  const activeStep = $(".step.active-step");
  const inputFields = activeStep.getElementsByClassName(inputClass);

  if (!activeStep || inputFields.length === 0) return;

  const inputFieldBlank = inputFields[0].value.length === 0;
  const button = document.querySelector(buttonClass);

  toggleButton(inputFieldBlank, button);
}

function toggleButton(condition, button) {
  if (condition) {
    button.disabled = true;
  } else {
    button.disabled = false;
  }
}

function handleIntegrationCredentials() {
  const credentialsFields = document.getElementsByClassName("js-integration-credentials");
  const credentialsArray = Array.from(credentialsFields);
  const requiredFields = credentialsArray.filter(el => el.hasAttribute("required"));
  const allRequiredFieldsPresent = requiredFields.every(element => element.value.trim() !== '');

  if (allRequiredFieldsPresent) {
    removeFlashContainer();
    createUniversalAPIIntegration(requiredFields);
    waitForFlashMessage().then(() => {
      const flashAlert = $(".flash--alert");
      if (flashAlert) return;

      nextStep();
      toggleSaveButton();
    });
  } else {
    nextStep();
    toggleSaveButton();
  }
}

function removeFlashContainer() {
  const flashContainers = Array.from(document.getElementsByClassName("flash-container"));
  if (flashContainers.length > 0) {
    flashContainers.forEach((flashContainer) => { flashContainer.remove() });
  }
}

function createUniversalAPIIntegration(requiredFields) {
  const url = new URL(requiredFields[0].dataset.url);
  let data = {};

  Array.from(requiredFields).forEach((el) => {
    data[el.dataset.attributeName] = el.value.trim();
  })

  const settings = { settings: data };
  sendRequest(url, "POST", settings);
}

function sendRequest(url, method, data) {
  const json_data = JSON.stringify(data)
  const loader = $("#js-loader");

  loader.classList.remove("hidden");
  fetch(url, {
    method: method,
    body: json_data,
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": $("meta[name='csrf-token']").getAttribute("content"),
    },
  })
  .then((response) => { return response.json()})
  .then((data) => {
    const flash = `<div class="flash-container"><div class="flash-center">\
      <div class="flash flash--${data.type}" data-close-message="Click">\
      <i class="flash__icon"></i> ${data.message}</div></div></div>`

    $(".page-container").insertAdjacentHTML("beforeend", flash);
    document.dispatchEvent(new Event("flash:load"));
  })
  .finally(() => loader.classList.add("hidden"))
}

function scrollToAuthorizeButton() {
  const authorizeButton = document.querySelector('.js-authorize-button');
  if (authorizeButton && authorizeButton.dataset.clicked === "true") {
    authorizeButton.scrollIntoView({ behavior: 'smooth' });
  }
}

function waitForFlashMessage() {
  return new Promise((resolve) => {
    // Create a new observer
    const observer = new MutationObserver((mutationsList, observer) => {
      // Look through all mutations that just occured
      for(let mutation of mutationsList) {
        // If the addedNodes property has one or more nodes
        if(mutation.addedNodes.length) {
          for(let node of mutation.addedNodes) {
            // Check if the added node is our target flash message
            if(node.classList && node.classList.contains('flash-container')) {
              // If so, disconnect the observer and resolve the promise
              observer.disconnect();
              resolve();
              return;
            }
          }
        }
      }
    });

    // Start observing the document with the configured parameters
    observer.observe(document, { childList: true, subtree: true });
  });
}

import { $$ } from "../utils/$";
import { initializeDropdowns, tearDownDropdowns } from "../utils/searchable-dropdown-utils";
import Choices from "choices.js";

// Initializes Choices on initial page load (when the Mutation Observer isn't
// invoked)
document.addEventListener("DOMContentLoaded", (event) => {
  initializeDropdowns(document);
});

// De-initializes Choices so that dropdowns are re-initialized when navigating
// back to the page using the browser back button.
document.addEventListener("turbolinks:before-cache", function() {
  tearDownDropdowns(document);
});

// Watch DOM mutations and initialize Choices on any new dropdowns.
var observer = new MutationObserver(function(mutations) {
  mutations.forEach(function(mutation) {
    mutation.addedNodes.forEach(function(addedNode) {
      if(addedNode.classList) {
        initializeDropdowns(addedNode);
      }
    });
  });
});
observer.observe(document, {childList: true, subtree: true});


document.addEventListener("side-panel:load", () => {
  disableChoiceElements();
  initializeDisableInputControllers();
});

function disableChoiceElements() {
  document.querySelectorAll(".disabled-container .js-searchable-dropdown.js-disable-inputs-target")
    .forEach(selectElement => selectElement._choices.disable());
}

function initializeDisableInputControllers() {
  document.querySelectorAll(".js-disable-inputs-controller .js-disable-inputs-source")
    .forEach(source => source.addEventListener("change", handleDisableInputEvent));
}

function handleDisableInputEvent(e) {
  const controller = e.currentTarget.closest(".js-disable-inputs-controller");
  const checked = e.currentTarget.checked;
  const disabled = shouldDisable(controller, checked);

  toggleDisableInput(controller, disabled);
}

function shouldDisable(controller, checked) {
  if (controller.dataset.disableInputsState === "checked") {
    return checked;
  } else {
    return !checked;
  }
}

function toggleDisableInput(controller, disabled) {
  controller.classList.toggle("disabled-container", disabled);

  const targets = controller.querySelectorAll(".js-disable-inputs-target");
  targets.forEach((item) => {
    if (item.classList.contains("js-searchable-dropdown")) {
      if(disabled) {
        item._choices.disable();
      } else {
        item._choices.enable();
      }
    } else {
      item.disabled = disabled;
      item.classList.toggle("disabled", disabled);
    }
  });
}

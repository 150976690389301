import { $$ } from "utils/$";

document.addEventListener("turbolinks:load", () => {
  $$(".flash").forEach(initializeFlashHandler);
});
document.addEventListener("flash:load", () => {
  $$(".flash").forEach(initializeFlashHandler);
});

function initializeFlashHandler($flash) {
  const fiveSeconds = 5000;
  const { resetTimeout, stopTimeout } = setResettableTimeout(() => removeFlash($flash), fiveSeconds);

  $flash.addEventListener("mouseover", stopTimeout);
  $flash.addEventListener("mouseout", resetTimeout);
  $flash.addEventListener("click", (e) => {
    e.preventDefault();
    stopTimeout();
    removeFlash($flash);
  });
}

function removeFlash($flash) {
  $flash.classList.add("flash--closed");
}

function setResettableTimeout(callback, delay) {
  let timeout = setTimeout(callback, delay);

  function resetTimeout () {
    stopTimeout();
    timeout = setTimeout(callback, delay);
  }

  function stopTimeout() {
    clearTimeout(timeout);
  }

  return {
    resetTimeout: resetTimeout,
    stopTimeout: stopTimeout,
  }
}

document.addEventListener("turbolinks:before-cache", () => {
  $$(".flash").forEach($flash => $flash.remove());
});

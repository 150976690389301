import { $ } from "../utils/$";
import * as Form from "../utils/Form";
import delegate from "delegate";
import directImageUpload from "./direct-image-upload";

const BUDGET_PREVIEW = "#js-budget-preview";
const BUDGET_PREVIEW_CATEGORY = "#js-budget-preview-for-starter-kit";

delegate(".js-reload-budget-preview", "change", (e) => {
  if (!$(BUDGET_PREVIEW)) return;

  const form = e.target.closest("form");

  Form.morphStateInto(form, { replace: BUDGET_PREVIEW });
});

delegate(".js-reload-budget-preview", "change", (e) => {
  if (!$(BUDGET_PREVIEW_CATEGORY)) return;

  const form = e.target.closest("form");

  Form.morphStateInto(form, { replace: BUDGET_PREVIEW_CATEGORY });
});

delegate(".js-image-preview-field-avatar", "change", uploadFile);

function uploadFile(e) {
  directImageUpload(e.target, () => {});
}

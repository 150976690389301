import delegate from "delegate";

delegate(".js-dustin-terms, .js-velory-terms", "change", () => {
  const dustinTermsChecked = document.querySelector(".js-dustin-terms").checked;
  const veloryTermsChecked = document.querySelector(".js-velory-terms").checked;
  const button = document.querySelector(".js-dustin-confirm-button");

  if (dustinTermsChecked && veloryTermsChecked) {
    button.disabled = false;
  } else {
    button.disabled = true;
  }
});

delegate(".js-dustin-sign-up-form input", "focusout", (event) => {
  const input = event.delegateTarget;
  const name = input.name;
  const value = input.value;

  if (!name) return;
  if (!value) return;

  const eventName = "dustin_signup_input_event"
  const length = value.length
  const eventAttributes = { name, length }

  trackDustinSignupEvent(eventName, eventAttributes)
});

delegate(".js-dustin-sign-up-form [type='checkbox']", "change", (event) => {
  const input = event.delegateTarget;
  const name = input.name;

  if (name.checked === false) return;

  const length = 1
  const eventName = "dustin_signup_input_event"
  const eventAttributes = { name, length }

  trackDustinSignupEvent(eventName, eventAttributes)
});

delegate(".js-skip-for-now", "click", () => {
  const eventName = "skip_for_now_clicked"

  trackDustinSignupEvent(eventName, {})
});

delegate(
  ".js-add_company_info, .js-configure_budgets, .js-import_users, .js-test_store_as_employee",
  "click",
  (e) => {

  const card = e.delegateTarget.dataset.card

  if (e.target.classList.contains(`js-${card}-learn-more`)) return;

  const eventName = "welcome_page_cta_clicked"
  const eventAttributes = { card }

  if (!card) return;

  trackDustinSignupEvent(eventName, eventAttributes)
});

delegate(
  ".js-add_company_info-learn-more, .js-configure_budgets-learn-more, .js-import_users-learn-more, .js-test_store_as_employee-learn-more",
  "click",
  (e) => {
  const card = e.delegateTarget.dataset.card
  const eventName = "welcome_page_read_more_clicked"
  const eventAttributes = { card }

  if (!card) return;

  trackDustinSignupEvent(eventName, eventAttributes)
});

function trackDustinSignupEvent(name, attributes) {
  if (typeof analytics !== "object") return;

  analytics.track(name, attributes);
}

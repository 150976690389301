import delegate from "delegate";
import { $ } from "../utils/$";

delegate(".js-nav-mobile-button", "click", (e) => {
  $(".nav-mobile-icon").classList.toggle("nav-mobile-icon-opened");

  const shouldOpen = $(".nav-mobile-wrapper").classList.contains("is-closed");

  $(".nav-mobile-wrapper").classList.toggle("is-open", shouldOpen);
  $(".nav-mobile-wrapper").classList.toggle("is-closed", !shouldOpen);
});
